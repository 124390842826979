import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.less';
import {CheckOutlined, CopyOutlined} from "@ant-design/icons";
import {copyText} from "@/utils/common-utils";
import mermaid from 'mermaid';
import 'katex/dist/katex.min.css';
import katex from 'katex';

type CodeComponentProps = React.ClassAttributes<HTMLElement> & React.HTMLAttributes<HTMLElement>;

const CodeComponent: React.FC<CodeComponentProps> = ({ className, children }) => {
    const [copied, setCopied] = useState(false);
    const ref = useRef<HTMLElement | null>(null);
    const languageMatch = /language-(\w+)/.exec(className || '');
    const language = languageMatch ? languageMatch[1] : '';

    const handleCopy = async () => {
        await copyText(ref?.current?.textContent as string);
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
    };

    useEffect(() => {
        if (language === 'mermaid' && ref.current) {
            mermaid.initialize({ startOnLoad: true });
            // 渲染为img
            mermaid.init({
            }, ref.current).catch(error => {
                console.error('Error rendering mermaid:', error?.message);
            });
            mermaid.contentLoaded();
        } else if ((language === 'latex') && ref.current) {
            try {
                ref.current.innerHTML = katex.renderToString(ref.current.textContent || '', {
                    throwOnError: false,
                });
            } catch (error) {
                console.error('Error rendering LaTeX:', error);
            }
        }
    }, [language, children]);

    const showHeader = () => {
        return !(language === 'mermaid' || language === 'latex' || !language);

    }

    return (
        <>
            {
                showHeader() && <div className={styles['code-wrapper']}>
                    <div className={styles['toolbar']}>
                        <span className={styles.lang}>{language}</span>
                        <button onClick={handleCopy} className={styles['copy-btn']}>
                            {copied ? <CheckOutlined /> : <CopyOutlined />}
                            <span> {copied ? '已复制' : '复制'}</span>
                        </button>
                    </div>
                </div>
            }
            <code style={{ whiteSpace: 'pre-wrap'}} ref={ref} className={className}>
                {children}
            </code>
        </>
    );
};

export default CodeComponent;
