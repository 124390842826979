import React, {useEffect, useState} from 'react';
import styles from './index.module.less';
import Sider from "antd/es/layout/Sider";
import {useMenuStore} from "@/store/menu";
import {App, Button, ConfigProvider, Divider, Drawer, Flex, Menu} from "antd";
import './override.less';
import {ItemType} from "antd/es/menu/interface";
import icon from '@/assets/icon.png'
import {
    CloseOutlined,
    CustomerServiceOutlined,
    MessageOutlined,
    NumberOutlined,
    StarOutlined,
    ToolOutlined
} from "@ant-design/icons";
import HistoryList from "@/components/history-list";
import {useChatStore} from "@/store/chat";
import {useLocation, useNavigate} from "react-router-dom";
import {useLocalStorageState} from "ahooks";
import {CACHE_KEY} from "@/constant";

const menuItems: (ItemType & { isFinish?: boolean })[] = [
    {
        key: 'chat',
        icon: <NumberOutlined/>,
        label: '对话',
        isFinish: true,
    },
    {
        key: 'music',
        icon: <CustomerServiceOutlined/>,
        label: '音乐',
        isFinish: false,
        disabled: true,
    },
    {
        key: 'discuss',
        icon: <MessageOutlined/>,
        label: '讨论',
        isFinish: false,
        disabled: true,
    },
    {
        key: 'like',
        icon: <StarOutlined/>,
        label: '收藏',
        isFinish: false,
        disabled: true,
    },
    {
        key: 'tool',
        icon: <ToolOutlined/>,
        label: '工具',
        isFinish: true,
    },
];

const ETSider: React.FC = () => {
    const collapsed = useMenuStore(state => state.collapsed);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('chat');
    const {message} = App.useApp();
    const [params, setParams] = useLocalStorageState(CACHE_KEY.PARAMS, {
        defaultValue: {}
    });
    const {visible, setVisible} = useChatStore(s => ({
        visible: s.openList,
        setVisible: s.setOpenList,
    }));

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        setActiveKey(path);
    }, [location.pathname]);

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        // 记录参数
        setParams(pre => {
            return {
                ...pre,
                [path]:
                location.search
            }
        })
    }, [location.pathname, location.search, setParams]);

    const handleMenu = ({key}: { key: string }) => {
        if (!menuItems.find(item => item.key === key)?.isFinish) {
            return message.info('测试功能，正在陆续开放，敬请期待！');
        }
        // 获取缓存的参数
        // @ts-ignore
        navigate(`/home/${key}${params[key] ? params[key] : ''}`);
    }

    return (
        <div className={styles['sider-wrapper']} style={{width: collapsed ? 80 : 212}}>
            <Sider collapsed={collapsed} collapsedWidth={48} width={180} className={styles.sider}>
                <img alt="logo" style={{width: collapsed ? 24 : 90}}
                     className={styles.logo}
                     src={icon}
                />
                <ConfigProvider theme={{
                    components: {
                        Menu: {
                            itemBorderRadius: 8,
                        }
                    }
                }}>
                    <Menu onClick={handleMenu} inlineIndent={48} mode="inline" className={styles.menu}
                          selectedKeys={[activeKey]}
                          items={menuItems.map((item: any) => {
                              return {
                                  key: item.key,
                                  icon: item.icon,
                                  label: item.label,
                                  disabled: item.disabled,
                              };
                          })}/>
                </ConfigProvider>
                <Divider style={{margin: 8, fontSize: 13}} orientation="left">
                </Divider>
                <Drawer
                    destroyOnClose
                    styles={{body: {padding: 0}}}
                    closeIcon={null}
                    placement="left"
                    open={visible}
                    width={260}
                    onClose={() => setVisible(false)}
                >
                    <Flex style={{padding: '16px 12px'}} justify="space-between" align="center">
                        <h4>历史记录</h4>
                        <Button onClick={() => setVisible(false)} style={{width: 24, height: 24, padding: 0}}
                                type="text"><CloseOutlined/></Button>
                    </Flex>
                    <Divider style={{margin: 0}}/>
                    <HistoryList/>
                </Drawer>
            </Sider>
        </div>
    )
}

export default ETSider;
