import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.less';
import {Flex} from "antd";
import {
    FastBackwardOutlined,
    FastForwardOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {useMusicStore} from "@/store/music";
import {ColorUtils} from "@/utils/color-utils";
import EternalIcon from "@/components/icon";
import {PlayState} from "@/constant/music";
import {AudioStore} from "@/store/music/audio-store";
import {millisecondsToMinutesAndSeconds} from "@/utils/common-utils";
import {useDrag} from "@/hooks/useDrag";
import Frequency from "@/pages/home/music/components/player/frequency";

const PlayerController: React.FC = () => {
    const progressBarRef = React.useRef<HTMLDivElement>(null);
    const sliderRef = React.useRef<HTMLDivElement>(null);
    const [curTime, setCurTime] = useState(0);

    const {color: mainColor, src, playState, duration, currentTime, canplay} = useMusicStore(state => (
        {
            color: state.mailColor,
            src: state.coverSrc,
            playState: state.playState,
            setPlayState: state.setPlayState,
            duration: state.duration,
            currentTime: state.currentTime,
            canplay: state.canplay,
        }
    ));

    const color = useMemo(() => ColorUtils.adjustToTargetBrightness(mainColor, 200), [mainColor]);

    const handlePlay = async (state: PlayState) => {
        await AudioStore.getInstance().setPlayState(state);
    }
    const {percent: sliderPercent, setPercent} = useDrag(progressBarRef, sliderRef,
        (percent) => {
            setCurTime(percent * duration);
        },
        (percent) => {
            AudioStore.getInstance().setCurrentTime(percent * duration / 100);
        });

    useEffect(() => {
        const percent = currentTime / duration * 100;
        setPercent(percent);
        setCurTime(currentTime);
    }, [currentTime, duration]);

    return (
        <div className={styles['player-controller']}>
            <div className={styles['frequency-wrap']}>
                <Frequency/>
            </div>
            <div className={styles['progress-bar']} ref={progressBarRef}>
                <div style={{backgroundColor: color, width: `${sliderPercent}%`}}
                     className={styles['progress-bar-active']}>
                </div>
                <div className={styles['fake-bar']}>
                    <div style={{backgroundColor: color, left: `${sliderPercent}%`}}
                         className={styles.slider}>
                        <p ref={sliderRef}> {millisecondsToMinutesAndSeconds(curTime)}/{millisecondsToMinutesAndSeconds(duration)}</p>
                    </div>
                </div>
            </div>
            <img
                className={styles.img}
                src={src} alt=""
            />
            <Flex className={styles['ctr-wrap']}>
                <button title="减速" style={{color}} className={styles['ctr-btn']}><FastBackwardOutlined/></button>
                <button title="上一首" style={{color}} className={styles['ctr-btn']}><StepBackwardOutlined/></button>
                <button title={playState === PlayState.PLAY ? '暂停' : '播放'}
                        onClick={() => handlePlay(playState === PlayState.PLAY ? PlayState.PAUSE : PlayState.PLAY)}
                        style={{color}} className={styles['ctr-btn']}>
                    {playState === PlayState.PLAY ?
                        <EternalIcon type="icon-zanting"/> :
                        <EternalIcon type="icon-icon_play"/>}
                </button>
                <button title="下一首" style={{color}} className={styles['ctr-btn']}><StepForwardOutlined/></button>
                <button title="加速" style={{color}} className={styles['ctr-btn']}><FastForwardOutlined/></button>
            </Flex>
            <Flex gap={16}>
                <button className={styles.btn}>
                    <EternalIcon type="icon-yinliang"/>
                </button>
                {/*@ts-ignore*/}
                <button className={styles['list-btn']} style={{'--hover-color': color}}>
                    <UnorderedListOutlined/>
                </button>
            </Flex>
        </div>
    )
}

export default PlayerController;
