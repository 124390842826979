import {create} from "zustand";
import {API} from "@/service/types";

export interface IChatStore {
    selectedText: string;
    addSelected: (text: string) => void;
    resetSelected: () => void;
    openList: boolean;
    setOpenList: (open: boolean) => void;
    contents: API.ConversationRes[];
    addContent: (value: API.ConversationRes) => void;
    clearContents: () => void;
    finished: boolean;
    setFinished: (value: boolean) => void;
}


export const useChatStore = create<IChatStore>((set, get) => {
    return {
        selectedText: '',
        openList: false,
        contents: [],
        finished: true,
        setFinished: (value: boolean) => {
            set({
                finished: value
            });
        },
        clearContents: () => {
            set({
                contents: []
            })
        },
        addContent: (value: API.ConversationRes) => {
            set({
                contents: [...get().contents, value]
            })
        },
        setOpenList: (open: boolean) => {
            set({
                openList: open
            })
        },
        addSelected: (text: string) => {
            set({
                selectedText: text
            })
        },
        resetSelected: () => {
            set({
                selectedText: ''
            })
        },
    }
});
