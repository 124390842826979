import React, {useCallback, useState} from 'react';
import {useAsyncEffect} from "ahooks";
import responseUtils from "@/utils/response-utils";
import {HubService} from "@/service/hub-service";
import {API} from "@/service/types";
import {Button, Checkbox, Flex, Popconfirm, Spin} from 'antd';
import HistoryItem from "@/components/history-list/history-item";
import styles from './index.module.less';
import {ClearOutlined} from "@ant-design/icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getSearchParam} from "@/utils/common-utils";

const HistoryList: React.FC = () => {
    const [list, setList] = useState<API.HistoryItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [checkedList, setCheckedList] = useState<Record<string, boolean>>({});
    const navigator = useNavigate();
    const updateList = async () => {
        setLoading(true);
        const {success} = await responseUtils(HubService.chatList);
        setLoading(false);
        success(data => {
            data?.forEach(d => {
                setCheckedList(prev => ({...prev, [d.cid]: false}));
            });
            data?.sort((a, b) => Number(b.createTime) - Number(a.createTime));
            setList(data);
        });
    }

    useAsyncEffect(async () => {
        await updateList();
    }, []);

    const onCheckedChange = useCallback((conversationId: string, checked: boolean) => {
        setCheckedList(prev => ({...prev, [conversationId]: checked}));
    }, []);

    const deleteChat = async (delList: object[]) => {
        setLoading(true);
        const {success} = await responseUtils(HubService.deleteChat, delList);
        setLoading(false);
        success(async () => {
            await updateList();
            // @ts-ignore
            if (delList?.map(s => s.cid)?.includes(getSearchParam('cid'))) {
               window.location.href = '/home/chat';
            }
        });
    }

    const onSelectAll = (checked: boolean) => {
        setCheckedList(prev => {
            const newList = {...prev};
            Object.keys(newList).forEach(s => newList[s] = checked);
            return newList;
        });
    }

    const onDelete = useCallback(async () => {
        const cids = Object.keys(checkedList).filter(s => checkedList[s]);
        const delObj = cids.map(c => {
            return {
                cid: c,
                sid: list.find(s => s.cid === c)
            }
        });
        await deleteChat(delObj);
    }, [checkedList, deleteChat, list]);

    return (
        <Spin spinning={loading}>
            <Flex gap={8} align="center" justify="space-between" className={styles['history-header']}>
                <p>共 {list?.length} 条记录</p>
                <div>
                    <Checkbox checked={Object.values(checkedList).every(v => v)}
                              onChange={e => onSelectAll(e.target.checked)}>全选</Checkbox>
                    <Popconfirm title="确定删除已选记录吗？"
                                onConfirm={onDelete}
                    >
                        <Button size="small" type="text">
                            <ClearOutlined/>
                        </Button>
                    </Popconfirm>
                </div>
            </Flex>
            <div className={styles['history-list']}>
                {
                    list?.map(item => <HistoryItem
                        deleteChat={deleteChat}
                        onCheckedChange={(checked) => onCheckedChange(item.cid, checked)}
                        checked={checkedList[item.cid]}
                        key={item.cid + item.createTime}
                        item={item}/>)
                }
            </div>
        </Spin>
    )
}

export default HistoryList;
