export default `[ti:]
[ar:]
[al:]
[by:天琴实验室AI生成v1.0]
[offset:0]
[00:00.00]追着风长大-茶二娘/礼越
[00:00.23]词：十二兄
[00:00.47]曲：罗旭阳
[00:00.71]编曲：李师珏
[00:00.94]混音：李晨曦
[00:01.18]和声：金钊/王超
[00:01.42]制作人：刘思萌/李师珏
[00:01.65]监制：涂涂
[00:01.89]营销推广：奇点万音@华声时代
[00:02.13]出品/发行：华声时代
[00:02.37]你啊等等呀 别跑太快找不到了家
[00:08.25]雨啊慢慢下 别在脸颊落成了泪花
[00:13.47]风啊轻轻刮 别让眼里迷进了尘沙
[00:18.87]你啊我啊他啊 故事多长啊
[00:23.49]来翻开这一话
[00:27.30]谁家的娃娃 光着脚丫 追风玩耍
[00:30.54]打盹的小猫 身上落了 两三枝花
[00:33.42]谁唱着童谣 翻过山坡 踩过水洼
[00:36.30]折纸的飞机 乘着南风 去追晚霞
[00:39.12]这边蜗牛呀 爬呀爬上葡萄架
[00:41.70]那边蚂蚁搬家 躲避大雨哗啦啦
[00:44.43]墙上丁老头 永远没有白头发
[00:47.28]我们看过了花 要追风沙
[00:49.95]你啊等等呀 别跑太快找不到了家
[00:55.68]雨啊慢慢下 别在脸颊落成了泪花
[01:00.93]风啊轻轻刮 别让眼里迷进了尘沙
[01:06.30]你啊我啊他啊 故事多长啊
[01:10.92]来翻开这一话
[01:28.74]谁家的娃娃 光着脚丫 追风玩耍
[01:31.98]打盹的小猫 身上落了 两三枝花
[01:34.86]谁唱着童谣 翻过山坡 踩过水洼
[01:37.74]折纸的飞机 乘着南风 去追晚霞
[01:40.38]这边蜗牛呀 爬呀爬上葡萄架
[01:43.14]那边蚂蚁搬家 躲避大雨哗啦啦
[01:45.87]墙上丁老头 永远没有白头发
[01:48.72]我们看过了花 要追风沙
[01:51.36]你啊等等呀 别跑太快找不到了家
[01:57.09]雨啊慢慢下 别在脸颊落成了泪花
[02:02.34]风啊轻轻刮 别让眼里迷进了尘沙
[02:07.71]你啊我啊他啊 故事多长啊
[02:12.33]来翻开这一话
[02:15.75]你啊等等呀 别跑太快找不到了家
[02:30.21]雨啊慢慢下 别在脸颊落成了泪花
[02:35.79]风啊轻轻刮 别让眼里迷进了尘沙
[02:41.31]你啊我啊他啊 故事多长啊
[02:46.89]你啊等等呀 别跑太快找不到了家
[02:52.56]雨啊慢慢下 别在脸颊落成了泪花
[02:58.11]风啊轻轻刮 别让眼里迷进了尘沙
[03:03.66]你啊我啊他啊 故事多长啊
[03:08.22]来翻开这一话
[03:11.97]你啊我啊他啊 故事多长啊
[03:16.62]来翻开这一话`
