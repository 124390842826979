import React from 'react';
import styles from './index.module.less';
import Album from "@/pages/home/music/components/player/album";
import {Flex} from "antd";
import Lyric from "@/pages/home/music/components/player/lyric";
import PlayerHeader from "@/pages/home/music/components/player/player-header";
import PlayerController from "@/pages/home/music/components/player/player-controller";
import {useMusicStore} from "@/store/music";

const Player: React.FC = () => {
    const {coverSrc} = useMusicStore(s => (
        {
            coverSrc: s.coverSrc,
        }
    ));

    return (
        <div className={styles.player} style={{backgroundImage: `url(${coverSrc})`}}>
            <div className={styles['center-wrap']}>
                <PlayerHeader/>
                <Flex wrap="wrap" gap={16} align="center" justify="center" className={styles.center}>
                    <div style={{width: 400}}>
                        <Album/>
                    </div>
                    <Lyric/>
                </Flex>
                <PlayerController/>
            </div>
        </div>
    )
}

export default Player;
