import React from "react";
import {createBrowserRouter, Navigate} from "react-router-dom";
import Home from "@/pages/home";
import Auth from "@/pages/auth";
import Unauthorized from "@/pages/unauthorized";
import NotFound from "@/pages/not-found";
import Chat from "@/pages/home/chat";
import Music from "@/pages/home/music";
import Discuss from "@/pages/home/discuss";
import Tool from "@/pages/home/tool";

interface Route {
    path: string;
    element: React.ReactNode;
    children?: Route[];
}

const routes: Route[] = [
    {
        path: '/',
        element: <Navigate to="/home/chat"/>
    },
    {
        path: '/home',
        element: <Home/>,
        children: [
            {
                path: 'chat',
                element: <Chat/>
            },
            {
                path: 'music',
                element: <Music/>
            },
            {
                path: 'discuss',
                element: <Discuss/>
            },
            {
                path: 'tool',
                element: <Tool/>
            }
        ]
    },
    {
        path: '/auth',
        element: <Auth/>
    },
    {
        path: '/403',
        element: <Unauthorized/>
    },
    {
        path: '/404',
        element: <NotFound/>
    },
    {
        path: '*',
        element: <Navigate to="/404"/>
    }
];

export default createBrowserRouter(routes);
