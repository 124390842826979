import React, {memo} from 'react';
import {API} from "@/service/types";
import styles from './index.module.less';
import {Avatar, Button, Checkbox, Flex} from "antd";
import {DeleteOutlined, ShareAltOutlined} from "@ant-design/icons";
import {timeStampToDate} from "@/utils/common-utils";
import {useNavigate} from "react-router-dom";
import {useChatStore} from "@/store/chat";
import {useLocalStorageState} from "ahooks";
import {CACHE_KEY} from "@/constant";

interface HistoryItemProps {
    item: API.HistoryItem;
    checked: boolean;
    onCheckedChange?: (checked: boolean) => void;
    deleteChat: (delObj: object[]) => void;
}

const HistoryItem: React.FC<HistoryItemProps> = ({deleteChat, item, checked, onCheckedChange}) => {
    const navigate = useNavigate();
    const {setVisible} = useChatStore(s => ({
        setVisible: s.setOpenList,
    }));
    const [, setServer] = useLocalStorageState<API.ServerItem>(CACHE_KEY.CHECK_SERVER);
    const toChatPage = () => {
        setServer(item.server);
        navigate('/home/chat?cid=' + item.cid, {replace: true});
        setVisible(false);
    }

    return (
        <Flex align="center" className={styles['history-item']}>
            <Checkbox checked={checked} onChange={e => onCheckedChange && onCheckedChange(e.target.checked)}
                      style={{marginRight: 8}}/>
            <Flex gap={4} vertical>
                <Button type="text" onClick={toChatPage} className={styles['btn-link']}>
                    <span className={styles.title}>{item?.title || '无标题'}</span>
                </Button>
                <p className={styles.time}>{item?.updateTime && timeStampToDate(Number(item?.updateTime))}</p>
            </Flex>
            <Avatar size="small" src={item?.server?.icon}/>
            <div className={styles['btn-group']}>
                {/*TODO: 分享*/}
                {/*<Button shape="circle" size="small" type="primary">*/}
                {/*    <ShareAltOutlined/>*/}
                {/*</Button>*/}
                <Button onClick={() => deleteChat([{
                    cid: item?.cid,
                    sid: item?.server?.sid
                }])} shape="circle" danger size="small"
                        type="primary">
                    <DeleteOutlined/>
                </Button>
            </div>
        </Flex>
    )
}

export default memo(HistoryItem);
