export enum DeviceType {
    Mobile = "mobile",
    Tablet = "tablet",
    Desktop = "desktop"
}

export function detectDeviceType(): DeviceType {
    const userAgent = navigator.userAgent.toLowerCase();

    const isMobile = /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    const isTablet = /ipad|android(?!.*mobile)/i.test(userAgent);

    if (isTablet) {
        return DeviceType.Tablet;
    } else if (isMobile) {
        return DeviceType.Mobile;
    } else {
        return DeviceType.Desktop;
    }
}
