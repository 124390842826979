import axios from "axios";
import CacheUtils from "@/utils/cache-utils";
import {CACHE_KEY, MessageCode} from "@/constant";
import router from "@/router";

const instance = axios.create({
    baseURL: 'https://api.iwithyou.cn',
    timeout: 6000
});

instance.interceptors.request.use((config) => {
    const token = CacheUtils.get<string>(CACHE_KEY.TOKEN);
    config.headers.Authorization = token;
    return config;
})

instance.interceptors.response.use(async (response) => {
    if (response.data?.code === MessageCode.UNAUTHORIZED) {
        CacheUtils.remove(CACHE_KEY.TOKEN);
        await router.navigate('/auth');
    }
    return response.data;
}, (error) => {
    return Promise.resolve(
        {
            code: error?.response?.status || 404,
            msg: error?.response?.statusText || '网络错误'
        }
    );
})

export const ASS_PROXY_URL = 'http://127.0.0.1:5000/proxy?url=';

export default instance;
