// workerSingleton.ts
import { WOKER_EVENT_NAMES } from "@/constant";

type SubscriberCallback<T> = (data: T) => void;

interface Message {
    eventName: WOKER_EVENT_NAMES;
    parameters: any;
}

interface ResponseMap {
    [eventName: string]: Promise<any>[];
}

let workerInstance: Worker | null = null; // 工作者单例
const pendingPromises: ResponseMap = {}; // 存储待处理的 Promise

// 获取工人实例
const getWorkerInstance = (): Worker => {
    if (!workerInstance) {
        workerInstance = new Worker('/worker.js');

        workerInstance.onmessage = (event: MessageEvent) => {
            const { eventName, data } = event.data;

            // 处理相应的 Promise
            if (pendingPromises[eventName]) {
                const resolve = pendingPromises[eventName].shift(); // 获取并移除第一个 Promise
                if (resolve) {
                    // @ts-ignore
                    resolve(data); // 解决该 Promise
                }
            }
        };
    }
    return workerInstance;
};

//  Promise
const postMessageToWorker = (message: Message): Promise<any> => {
    return new Promise((resolve) => {
        const worker = getWorkerInstance();
        // 将 resolve 函数存入待处理的 Promise 数组
        if (!pendingPromises[message.eventName]) {
            pendingPromises[message.eventName] = [];
        }
        // @ts-ignore
        pendingPromises[message.eventName].push(resolve);

        worker.postMessage(message);
    });
};

export const workerUtils = {
    postMessageToWorker,
};
