import React, {useImperativeHandle, useState} from 'react';
import {App, Avatar, Badge, Flex, Modal, Tag} from "antd";
import styles from './index.module.less';
import {ClusterOutlined} from "@ant-design/icons";
import {API} from "@/service/types";
import {useLocalStorageState, useRequest} from "ahooks";
import {HubService} from "@/service/hub-service";
import {CACHE_KEY} from "@/constant";

interface ModelItemProps {
    server: API.ServerItem;
    onChecked: (server: API.ServerItem) => void;
}

const ModelItem: React.FC<ModelItemProps> = ({server, onChecked}) => {
    const {message} = App.useApp();
    const handleCheck = (checkedServer: API.ServerItem) => {
        if (!server.status) {
            return message.error("服务不可用，请选择其他服务");
        }
        onChecked(server);
    }
    return (
        <Flex vertical gap={8} onClick={() => handleCheck(server)}
              className={styles.card}>
            <Flex gap={8} align="start">
                <div style={{marginTop: 8}}>
                    <Badge dot status={server.status ? 'success' : 'error'}>
                        <Avatar
                            shape="circle"
                            src={server.icon}
                            className={styles.avatar}>
                            {server.name?.slice(0, 1)}
                        </Avatar>
                    </Badge></div>
                <div>
                    {server.name}
                    <p className={styles.description}>  {server.description}</p>
                </div>
                {
                    server.checked &&
                    <div className={styles.status}>
                        使用中
                    </div>
                }
            </Flex>
            <Flex gap="4px 0" wrap={'wrap'}>
                {
                    server?.ability?.split('|')
                        ?.map((v, i) =>
                            <Tag key={i} style={{fontSize: 10}} color="geekblue">{v}</Tag>
                        )
                }
            </Flex>
            {
                server?.fileType?.length > 0 && <p className={styles.fileType}>
                    支持文件类型：
                    {
                        server?.fileType?.split('|').join(', ')
                    }
                </p>
            }
        </Flex>
    )
}

export interface ModelPickerRef {
    show: () => void
}

interface ModelPickerProps {
    refAction: React.MutableRefObject<ModelPickerRef | undefined>
}

const ModelPicker: React.FC<ModelPickerProps> = ({refAction}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [list, setList] = useState<API.ServerItem[]>([]);
    const {message} = App.useApp();
    const [selectSever, setSelectServer] = useLocalStorageState<API.ServerItem>(CACHE_KEY.CHECK_SERVER, {
        listenStorageChange: true,
    });
    useImperativeHandle(refAction, () => {
        return {
            show: () => {
                setIsOpen(true);
            }
        }
    });

    const handleChecked = (server?: API.ServerItem) => {
        setSelectServer(server);
        setList(list.map(v => {
            if (v.sid === server?.sid) {
                return {
                    ...v,
                    checked: true
                }
            } else {
                return {
                    ...v,
                    checked: false
                }
            }
        }));
    }

    useRequest(HubService.getServerList, {
        onSuccess: (data) => {
            if (selectSever) {
                data?.data.forEach((v) => {
                    if (v.sid === selectSever.sid) {
                        v.checked = true;
                    }
                })
            } else {
                setSelectServer(data?.data?.find(s => s.status));
            }
            setList(data?.data);
        },
        onError: () => {
            message.error("获取模型列表失败");
        }
    });
    return (
        <Modal
            footer={null}
            onCancel={() => setIsOpen(false)}
            closable
            open={isOpen}
            style={{
                minWidth: '60%',
            }}
            title={
                <>
                    <ClusterOutlined style={{marginRight: 6}}/>
                    选择模型
                    <span style={{
                        fontSize: 12,
                        color: '#878fa9',
                        fontWeight: 400,
                        marginLeft: 8
                    }}>对话开始之后需要创建新的对话才能修改已选模型</span>
                </>
            }>
            <Flex wrap="wrap" gap={16} style={{maxHeight: '50vh', overflow: 'auto', padding: 16}}>
                {
                    list.map(v =>
                        <ModelItem onChecked={handleChecked} key={v.id} server={v}/>
                    )
                }
            </Flex>
        </Modal>
    )
}

export default ModelPicker;
