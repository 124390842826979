import React, {memo, useState} from 'react';
import ChatItem from "@/pages/home/chat/components/chat-item";
import {API} from "@/service/types";
import {TempContentSingleInstance} from "@/store/vstore";
import {useChatStore} from "@/store/chat";

const ChatTemp: React.FC = () => {
    const [content, setContent] = useState<API.ConversationRes | null>(null);
    const finished = useChatStore(state => state.finished);

    TempContentSingleInstance.subscribe((data) => {
        setContent(data);
    });

    return (
        (content?.part && !finished) ? <ChatItem
            key={content?.part?.id as string + content?.part?.role as string}
            content={content as API.ConversationRes}
        /> : null
    )
}

export default memo(ChatTemp);
