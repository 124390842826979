import axios from "axios";

/**
 * 下载文件
 * @param url
 * @param fileName
 */
export async function downloadFile(url: string, fileName: string) {
    try {
        // 使用 axios 获取 blob 数据
        const response = await axios.get(url, {
            responseType: 'blob', // 设置响应类型为 blob
        });

        // 创建一个 Blob 对象，并将其作为 URL 生成
        const blob = response.data;
        const urlCreator = window.URL || window.webkitURL;
        const downloadUrl = urlCreator.createObjectURL(blob);

        // 创建一个 <a> 标签，并模拟点击事件来触发下载
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // 释放对象 URL
        urlCreator.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('下载文件时发生错误:', error);
    }
}

/**
 * 滚动到底部
 * @param element
 */
export const scrollToBottom = (element: HTMLDivElement) => {
    if (element) {
        element.scrollTo({behavior: 'smooth', top: element.scrollHeight});
    }
}

/**
 * 复制文本
 * @param text
 */
export const copyText = (text: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(error);
            });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.display = 'none';
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                resolve(true);
            } catch (e) {
                console.log(e)
                reject(e);
            } finally {
                document.body.removeChild(textArea);
            }
        }
    });
}


export interface FileInfo {
    name: string;
    size: string;
    type: string;
}

export function getFileInfo(file: File): FileInfo {
    return {
        name: file.name,
        size: (file.size / 1024 / 1024).toFixed(2), // 转换为 MB
        type: file.name.split('.').pop()?.toLowerCase() || 'unknown'
    };
}


export type FileType = 'ppt' | 'word' | 'excel' | 'pdf' | 'image' | 'txt' | 'other';

export function getFileType(fileName: string): FileType {
    // Extract the file extension
    const extension = fileName.split('.').pop()?.toLowerCase();

    if (!extension) {
        return 'other';
    }

    switch (extension) {
        case 'ppt':
        case 'pptx':
            return 'ppt';
        case 'doc':
        case 'docx':
            return 'word';
        case 'xls':
        case 'xlsx':
            return 'excel';
        case 'pdf':
            return 'pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'jfif':
        case 'gif':
        case 'bmp':
        case 'tiff':
        case 'webp':
        case 'svg':
        case 'psd':
            return 'image';
        case 'txt':
            return 'txt';
        default:
            return 'other';
    }
}

export function isImageFile(file: File): boolean {
    return file.type.startsWith('image/');
}

export function isScrollToBottom(element: HTMLDivElement): boolean {
    return element.scrollTop + element.clientHeight >= element.scrollHeight;
}

export function isScrollUp(element: HTMLDivElement): boolean {
    // 检查当前滚动位置与上一次记录的滚动位置比较
    const currentScrollTop = element.scrollTop;
    element.scrollTop += 1; // 增加滚动位置以避免 scrollTop 不变时的误判
    const scrollUp = element.scrollTop < currentScrollTop;
    element.scrollTop -= 1; // 恢复原始滚动位置
    return scrollUp;
}

export function timeStampToDate(timestamp: number): string {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// 全屏
export async function toggleFullscreen() {
    try {
        if (document.fullscreenElement === null) {
            await document.documentElement.requestFullscreen();
        } else {
            await document.exitFullscreen();
        }
    } catch (e) {
        console.error(e); // 使用console.error来记录错误
    }
}

/**
 * 将毫秒转换为时间
 * @param milliseconds
 */
export const millisecondsToMinutesAndSeconds = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}


export function getQueryParams(): Record<string, string> {
    const params = {};
    const queryString = window.location.search;

    // 去掉问号（如果存在）
    const queryStringWithoutQuestionMark = queryString.startsWith('?') ? queryString.slice(1) : queryString;

    // 分割查询字符串为参数数组
    const queryParams = queryStringWithoutQuestionMark.split('&');

    // 遍历参数数组，并解析每个参数
    queryParams.forEach(param => {
        const [key, value] = param.split('=');

        // 解码URI编码的参数值
        // @ts-ignore
        params[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });

    return params;
}

export function getSearchParam(param: string) {
    // 获取当前页面的URL
    const url = new URL(window.location.href);

    // 使用URLSearchParams获取查询参数
    const params = new URLSearchParams(url.search);

    // 返回指定参数的值
    return params.get(param);
}
