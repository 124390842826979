import {create} from "zustand";
import {API} from "@/service/types";

type IUserInfo = API.LoginResData['userInfo'] | null;

interface IAuthStore {
    userInfo: IUserInfo;
    setUserInfo: (userInfo: IUserInfo) => void;
}

export const useAuthStore = create<IAuthStore>(set => {
        return {
            userInfo: null,
            setUserInfo: (userInfo: IUserInfo) => {
                set(() => ({userInfo}))
            }
        }
    }
)
