// @ts-ignore
import {throttle} from 'lodash';

export interface LycInfo {
    title: string;
    singer: string;
    timeLyc: Array<{ time: number, lyric: string }>;
}

export const getLycInfo = (lyc: string): LycInfo => {
    const regex = /(\[ti:(.*?)\])|(\[ar:(.*?)\])|(\[by:(.*?)\])|(\[(\d{2}):(\d{2})\.(\d{2})\](.*))/g;
    let match;
    let title = '';
    let singer = '';
    let timeLyc: Array<{ time: number, lyric: string }> = [];

    while ((match = regex.exec(lyc)) !== null) {
        if (match[2]) title = match[2];
        else if (match[4]) singer = match[4];
        else if (match[10] && match[11]) {
            const time = parseInt(match[8], 10) * 60 * 1000 + parseInt(match[9], 10) * 1000 + parseInt(match[10], 10);
            timeLyc.push({time, lyric: match[11]});
        }
    }

    return {
        title,
        singer,
        timeLyc
    };
};

const resetAttr = (container: HTMLDivElement) => {
    const children = container.children;
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        child.removeAttribute('state');
        // @ts-ignore
        child.style.setProperty('opacity', '1');
        // @ts-ignore
        child.style.setProperty('filter', 'blur(0px)');
    }
}

// 改变该元素前面以及后面的元素递增透明度以及递增模糊的

const changeOpacityAndBlur = (el: Element) => {

    let pre = el.previousElementSibling;
    let preOpacity = 1;
    let preBlur = 0;
    const minOpacity = 0;
    const maxBlur = 5;
    const opacityStep = 0.22;
    const blurStep = 0.3;

    while (pre) {
        // @ts-ignore
        pre.style.setProperty('opacity', preOpacity > minOpacity ? `${preOpacity -= opacityStep}` : minOpacity);
        // @ts-ignore
        pre.style.setProperty('filter', `blur(${preBlur > maxBlur ? maxBlur : preBlur += blurStep}px)`);
        pre = pre.previousElementSibling;

    }

    let next = el.nextElementSibling;
    let nextOpacity = 1;
    let nextBlur = 0;
    while (next) {
        // @ts-ignore
        next.style.setProperty('opacity', nextOpacity > minOpacity ? `${nextOpacity -= opacityStep}` : minOpacity);
        // @ts-ignore
        next.style.setProperty('filter', `blur(${nextBlur > maxBlur ? maxBlur : nextBlur += blurStep}px)`);
        next = next.nextElementSibling;
    }
}

export const scrollToCurrentTime = (container: HTMLDivElement, currentTime: number) => {
    let containerParent = container.parentElement as HTMLElement | null;
    let foundChild: Element | null = null;
    let minDiff = Infinity;

    const children = Array.from(container.children);
    for (const child of children) {
        // @ts-ignore
        const time = parseInt(child.dataset.time);
        if (time <= currentTime) {
            const diff = currentTime - time;
            if (diff < minDiff) {
                minDiff = diff;
                foundChild = child;
            }
        } else {
            break;
        }
    }

    if (foundChild && containerParent) {
        resetAttr(container);
        changeOpacityAndBlur(foundChild);
        // @ts-ignore
        container.style.top = `${-foundChild.offsetTop + containerParent.clientHeight / 2}px`;
        // smoothScroll(containerParent, targetScrollTop);
        foundChild.setAttribute('state', 'active');
    }
};

export const renderFrequencyData = (audioContext: AudioContext, analyser: AnalyserNode, canvas: HTMLCanvasElement) => {
    const canvasCtx = canvas.getContext('2d');
    if (!canvasCtx) return;
    const updateFFTSize = () => {
        const newFftSize = Math.pow(2, Math.ceil(Math.log2(canvas.width)));
        analyser.fftSize = Math.min(newFftSize);
    };

    const setCanvasSize = () => {
        canvas.width = window.innerWidth;
        updateFFTSize();
    };

    setCanvasSize();
    analyser.maxDecibels = 30;
    analyser.smoothingTimeConstant = 0.9;
    analyser.minDecibels = -100;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);


    const draw = () => {
        analyser.getByteFrequencyData(dataArray);
        const maxValue = Math.max(...dataArray);
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < dataArray.length; i++) {
            const scaledHeight = (dataArray[i] / maxValue) * canvas.height;
            const x = i * 4;
            const y = canvas.height - scaledHeight;

            canvasCtx.fillStyle = '#fff';
            // 根据高度调整颜色
            canvasCtx.fillStyle = `rgba(200, 200, 200, ${scaledHeight / canvas.height})`;
            canvasCtx.fillRect(x, y, 2, scaledHeight);
        }
    };

    const animate = () => {
        draw();
        requestAnimationFrame(animate);
    };

    animate();
    window.addEventListener('resize', setCanvasSize);
};
