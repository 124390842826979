import { useEffect, useCallback } from 'react';

const useSelectText = (
    ref: React.RefObject<HTMLElement>,
    callback: (selectedText: string, position: { x: number, y: number, width: number }) => void
) => {
    const handleMouseUp = useCallback(
        (event: MouseEvent) => {
            const targetElement = event.target as HTMLElement;

            if (ref.current && ref.current.contains(targetElement)) {
                const selectedText = getSelectedText();
                if (selectedText) {
                    const position = getSelectionPosition(ref.current);
                    callback(selectedText, position);
                }
            }
        },
        [ref, callback]
    );

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseUp]);

    const getSelectedText = (): string | null => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            return selection.toString();
        }
        return null;
    };

    const getSelectionPosition = (container: HTMLElement): { x: number; y: number; width: number } => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const rects = range.getClientRects();

            // Check selection direction
            const isForward = isSelectionForward(selection);

            let rect;
            const popW = 100; // 弹窗宽度
            if (isForward) {
                rect = rects[rects.length - 1]; // Last line's rect
                return {
                    x: rect.left - container.getBoundingClientRect().left + rect.width - popW,
                    y: rect.bottom - container.getBoundingClientRect().top + window.scrollY + 8,
                    width: rect.width,
                };
            } else {
                rect = rects[0]; // First line's rect
                return {
                    x: rect.left - container.getBoundingClientRect().left,
                    y: rect.top - container.getBoundingClientRect().top + window.scrollY - rect.height - 20,
                    width: rect.width,
                };
            }
        }
        return { x: 0, y: 0, width: 0 };
    };

    const isSelectionForward = (selection: Selection): boolean => {
        const { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
        if (anchorNode === focusNode) {
            return anchorOffset < focusOffset;
        }
        const range = document.createRange();
        range.setStart(anchorNode!, anchorOffset);
        range.setEnd(focusNode!, focusOffset);
        return range.collapsed === false;
    };

    const clearSelection = () => {
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };

    return {
        clearSelection
    }
};

export default useSelectText;
