import React from 'react';
import Link from "antd/es/typography/Link";
import styles from './index.module.less';
import {Collapse, Image} from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

export interface LinkItem {
    title: string;
    url: string;
}


export interface LinkListProps {
    links: LinkItem[]
}

const LinkList: React.FC<LinkListProps> = ({links}) => {
    return (
        <div className={styles['link-wrap']}>
            <Collapse  bordered={false}>
                <CollapsePanel className={styles['link-list']} key={1} header="参考链接">
                    {
                        links?.map((l,i) => {
                            const baseUrl = l.url.split('/').slice(0, 3).join('/');
                            return <Link
                                target="_blank"
                                href={l.url}
                                key={l.url + i}
                                className={styles['link-item']}
                            >
                                <Image src={baseUrl + '/favicon.ico'} width={18} height={18}/>
                                {l.title}
                            </Link>
                        })
                    }
                </CollapsePanel>
            </Collapse>
        </div>
    )
}

export default LinkList;
