import React, {useEffect} from 'react';
import useModal from 'antd/es/modal/useModal';

export const useNotice = () => {
    const [modal, contextHolder] = useModal();

    const showNotice = (title: string, content: string) => {
        modal.info({
            title,
            content,
            centered: true,
            okText: '确定',
        });
    };

    // 使用 useEffect 在钩子内部渲染 contextHolder
    useEffect(() => {
        // 这里没有额外的逻辑，只是确保 contextHolder 被渲染
        return () => {
        };
    }, []);

    return {
        showNotice,
        contextHolder
    };
};
