import React from 'react';
import {Badge, Collapse} from "antd";
import {API} from "@/service/types";
import '@/style.less';
import styles from './index.module.less';
import ReactMarkdown from 'react-markdown';
import CodeComponent from "@/pages/home/chat/components/markdown-ext/code-component";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import rehypeKatex from "rehype-katex";
import LinkComponent from "@/pages/home/chat/components/markdown-ext/link-component";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import {MessageService} from "@/service/message";

interface IProps {
    notices: API.NoticeItem[];
    onRead?: () => void;
}

const Notice: React.FC<IProps> = ({notices, onRead}) => {
    const handleRead = async (id: string[] | string) => {
        const ids = Array.isArray(id) ? id : [id];
        if (!ids.length) return;
        const noticeItem = notices.find(item => item.id === Number(ids[0]));
        if (noticeItem?.isRead) return;
        await MessageService.readMessage(ids?.map(id => Number(id)));
        onRead?.();
    }

    return (
        <div className={styles['notice-container']}>
            <Collapse accordion={true} onChange={ks => handleRead(ks)} items={
                notices.map(item => ({
                    key: item.id,
                    extra: <div className={'notice-time'}>{item.createTime}</div>,
                    label: <Badge dot={!item?.isRead} offset={[4, 0]}>
                        <div className={'notice-title'}>{item.title}</div>
                    </Badge>,
                    children: <>
                        <ReactMarkdown
                            className={"md-theme"}
                            children={item?.content}
                            remarkPlugins={
                                [
                                    remarkGfm,
                                    remarkEmoji,
                                    remarkMath]}
                            rehypePlugins={
                                [
                                    rehypeHighlight,
                                    rehypeKatex,
                                    rehypeRaw,
                                ]
                            }
                            components={{
                                code: CodeComponent,
                                a: LinkComponent,
                            }}
                        />
                    </>
                }))
            }/>
        </div>
    )
}

export default Notice;
