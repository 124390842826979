import {PlayState} from "@/constant/music";

export class AudioStore {
    private audio: HTMLAudioElement = new Audio();
    private static instance: AudioStore;
    public audioContext: AudioContext | null = null;
    public audioAnalyser: AnalyserNode | null = null;
    public audioSource: MediaElementAudioSourceNode | null = null;

    static getInstance(): AudioStore {
        if (!AudioStore.instance) {
            AudioStore.instance = new AudioStore();
        }
        return AudioStore.instance;
    }

    setSrc(src: string) {
        this.audio.src = src;
        this.audio.crossOrigin = 'anonymous';
        return this;
    }

    async initContext() {
        if (this.audioContext) {
            await this.audioContext.resume();
            return
        }
        this.audioContext = new AudioContext();
        this.audioAnalyser = this.audioContext.createAnalyser();
        this.audioSource = this.audioContext.createMediaElementSource(this.audio);
        this.audioSource.connect(this.audioAnalyser);
        this.audioAnalyser.connect(this.audioContext.destination);
        return this;
    }

    async setPlayState(playState: PlayState) {
        switch (playState) {
            case PlayState.PLAY:
                await this.audio.play();
                await this.initContext();
                break;
            case PlayState.PAUSE:
                this.audio.pause();
                break;
            case PlayState.STOP:
                this.audio.pause();
                this.audio.currentTime = 0;
                break;
        }
        return this;
    }

    setCurrentTime(currentTime: number) {
        this.audio.currentTime = currentTime / 1000;
        return this;
    }

    setMuted(muted: boolean) {
        this.audio.muted = muted;
        return this;
    }

    setVolume(volume: number) {
        this.audio.volume = volume;
        return this;
    }

    setPlaybackRate(playbackRate: number) {
        this.audio.playbackRate = playbackRate;
        return this;
    }

    setLoop(loop: boolean) {
        this.audio.loop = loop;
        return this;
    }

    onStatusChange(callback: (status: PlayState) => void) {
        this.audio.onplay = () => {
            callback(PlayState.PLAY);
        };
        this.audio.onpause = () => {
            callback(PlayState.PAUSE);
        };
        this.audio.onended = () => {
            callback(PlayState.STOP);
        };
        this.audio.onerror = () => {
            callback(PlayState.STOP);
        };
        return this;
    }

    onTimeUpdate(callback: (currentTime: number) => void) {
        this.audio.ontimeupdate = () => {
            // 转换为毫秒
            callback(this.audio.currentTime * 1000);
        };
        return this;
    }

    onDurationChange(callback: (duration: number) => void) {
        this.audio.ondurationchange = () => {
            callback(this.audio.duration * 1000);
        };
        return this;
    }

    onVolumeChange(callback: (volume: number) => void) {
        this.audio.onvolumechange = () => {
            callback(this.audio.volume);
        };
        return this;
    }

    onPlaybackRateChange(callback: (playbackRate: number) => void) {
        this.audio.onratechange = () => {
            callback(this.audio.playbackRate);
        };
        return this;
    }

    onLoadStart(callback: () => void) {
        this.audio.onload = () => {
            callback();
        };
        return this;
    }

    onCanPlay(callback: (canplay: boolean) => void) {
        this.audio.oncanplay = () => {
            callback(true);
        };
        return this;
    }

    getFrequencyData() {
        if (!this.audioAnalyser) {
            return new Uint8Array(0);
        }
        const array = new Uint8Array(this.audioAnalyser.frequencyBinCount);
        this.audioAnalyser.getByteFrequencyData(array);
        return array;
    }
}
