import React from 'react';
import 'react-photo-view/dist/react-photo-view.css';
import {PhotoProvider, PhotoView} from 'react-photo-view';
import {Button, Flex} from "antd";
import {DownloadOutlined, RedoOutlined, UndoOutlined, ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import styles from './index.module.less';
import {downloadFile} from "@/utils/common-utils";

export interface IImage {
    src: string;
    alt: string;
}

type ImageViewerProps = {
    images: IImage[]
};

const ImageViewer: React.FC<ImageViewerProps> = ({images}) => {
    images.forEach(item => {
        if (item.src?.includes("?x-oss-process")) {
            item.src = item.src.split("?x-oss-process")[0];
        }
    });
    return (
        <PhotoProvider
            maskOpacity={0.7}
            bannerVisible
            toolbarRender={({onScale, scale, onRotate, rotate, index}) => {
                return (
                    <Flex align="center">
                        <Button className={styles['toolbar-btn']} type="text" key="s+"
                                onClick={() => onScale(scale + 1)}>
                            <ZoomInOutlined/>
                        </Button>
                        <Button className={styles['toolbar-btn']} type="text" key="s-"
                                onClick={() => onScale(scale - 1)}>
                            <ZoomOutOutlined/>
                        </Button>
                        <Button className={styles['toolbar-btn']} type="text" key="r+"
                                onClick={() => onRotate(rotate + 90)}>
                            <RedoOutlined/>
                        </Button>
                        <Button className={styles['toolbar-btn']} type="text" key="r-"
                                onClick={() => onRotate(rotate - 90)}>
                            <UndoOutlined/>
                        </Button>
                        <Button className={styles['toolbar-btn']} type="text" key="download"
                                onClick={() => downloadFile(images[index].src as string, images[index].src?.split('/').pop() ?? "")}>
                            <DownloadOutlined/>
                        </Button>
                    </Flex>
                );
            }}>
            <div className={styles['image-wrap']}>
                {
                    images?.map(({src, alt}) => (
                        <PhotoView key={src} src={src}>
                            <img className={styles.image} src={src} alt={alt}/>
                        </PhotoView>
                    ))
                }
            </div>
        </PhotoProvider>
    )
}

export default ImageViewer;
