import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Divider, Flex, Form, message} from "antd";
import styles from './index.module.less';
import ETInput from "@/pages/auth/components/et-input";
import {ArrowLeftOutlined, ArrowRightOutlined, WechatFilled} from "@ant-design/icons";
import ETCaptcha from "@/pages/auth/components/et-captcha";
import responseUtils from "@/utils/response-utils";
import {UserService} from "@/service/user-service";
import {CACHE_KEY, MailCodeType, PasswordReg, UserNameReg} from "@/constant";
import CacheUtils from "@/utils/cache-utils";
import {useNavigate} from "react-router-dom";
import logo from '@/assets/icon.png';
import {useAuthStore} from "@/store/auth";
import ThirdAuth, {AuthType} from "@/pages/auth/components/third-auth";
import ForgotPWD from "@/pages/auth/components/forgot-pwd";
import EternalIcon from "@/components/icon";

type TabKey = 'register' | 'login';

export interface LoginFormType {
    account: string;
    password: string;
}

export interface RegFormType {
    username: string;
    password: string;
    email: string;
    code: string;
}

const handleCaptcha = async (email: string) => {
    const {success, error} = await responseUtils(UserService.getMailCode, email, MailCodeType.Register);
    success(() => {
        message.success('验证码已发送, 请查收邮件');
    });
    error(() => {
        throw new Error('获取失败');
    });
};

const Auth: React.FC = () => {
    const [tabKey, setTabKey] = useState<TabKey>('login');
    const [form] = Form.useForm<RegFormType>();
    const formWrapperRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const cardRef = useRef<HTMLDivElement | null>(null);
    const {setUserInfo} = useAuthStore();
    const [thirdAuth, setThirdAuth] = useState(false);
    const [authType, setAuthType] = useState<AuthType | null>(null);
    const [forgotPwd, setForgotPwd] = useState(false);
    // 登录就去首页
    useEffect(() => {
        const token = CacheUtils.get<string | null>(CACHE_KEY.TOKEN, null);
        if (token) {
            navigate('/home/chat');
        }
    }, [navigate]);

    useEffect(() => {
        if (formWrapperRef) {
            formWrapperRef.current!.style.transform = `translateX(${tabKey === 'register' ? '-50%' : '0'})`;
            cardRef.current!.style.height = tabKey === 'register' ? '550px' : '480px';
        }
    }, [tabKey]);

    const handleLogin = useCallback(async (values: LoginFormType) => {
        const {success} = await responseUtils(UserService.login, values);
        success((data) => {
            // 存本地
            CacheUtils.set<string>(CACHE_KEY.TOKEN, data?.token);
            setUserInfo(data.userInfo);
            message.success('登录成功');
            navigate('/');
        });
    }, [navigate, setUserInfo]);

    const handleReg = useCallback(async (values: RegFormType) => {
        const {success, error} = await responseUtils(UserService.register, values);
        success(async () => {
            // 登录
            await handleLogin({
                account: values.email,
                password: values.password
            });
        });
        error(() => {
            message.error('注册失败');
        });
    }, [handleLogin]);

    return (
        <div className={styles.auth}>
            <Flex className={styles.card} ref={cardRef}>
                <div className={styles.logo}>
                    <img src={logo} alt="logo"/>
                </div>
                <div ref={formWrapperRef} className={styles.wrapper}>
                    <Form<LoginFormType>
                        onFinish={handleLogin}
                        layout="vertical"
                        className={styles.form}
                    >
                        <Form.Item
                            rules={[{
                                required: true,
                                message: '请输入用户名或邮箱'
                            }]}
                            name="account"
                            label="账号"
                        >
                            <ETInput type="text" placeholder="用户名 | 邮箱"/>
                        </Form.Item>

                        <Form.Item
                            rules={[{
                                required: true,
                                message: '请输入密码'
                            }]}
                            name="password"
                            label="密码"
                        >
                            <ETInput type="password" placeholder="密码"/>
                        </Form.Item>
                        <Flex justify="space-between">
                            <Button
                                onClick={() => setTabKey('register')}
                                className={styles['center-btn']}
                                type="text"
                            >
                                <ArrowRightOutlined/>
                                去注册
                            </Button>
                            <Button onClick={() => setForgotPwd(true)} className={styles['center-btn']} type="text">忘记密码？</Button>
                        </Flex>
                        <Flex justify="center">
                            <Button htmlType="submit" className={styles['form-btn']} type="primary">
                                登录
                            </Button>
                        </Flex>
                        <Divider style={{fontSize: 12}} children="其他登录方式"/>
                        <Flex justify="center" gap={20}>
                            <Button onClick={() => {
                                setThirdAuth(true);
                                setAuthType(AuthType.WECHAT);
                            }} title="微信登录" shape="circle" ghost>
                                <WechatFilled style={{fontSize: 20, color: '#10d168'}}/>
                            </Button>
                            <Button onClick={() => {
                                setThirdAuth(true);
                                setAuthType(AuthType.QQ);
                            }} title="QQ登录" shape="circle" ghost>
                                <EternalIcon type="icon-QQ" style={{fontSize: 19}}/>
                            </Button>
                        </Flex>
                    </Form>
                    <Form<RegFormType>
                        form={form}
                        className={styles.form + ' ' + styles['form-reg']}
                        onFinish={handleReg}
                        labelCol={{span: 6}}
                        layout="vertical"
                    >
                        <Form.Item label="用户名" name="username" rules={[{
                            required: true,
                            message: '支持[4-12位大小写字母、数字]'
                        }, {
                            pattern: UserNameReg,
                            message: '支持[4-12位大小写字母、数字]'
                        }]}>
                            <ETInput type="text" placeholder="用户名 [4-12位大小写字母、数字]"/>
                        </Form.Item>
                        <Form.Item label="邮箱" name="email" rules={[{
                            required: true,
                            message: '请输入邮箱'
                        }, {
                            type: 'email',
                            message: '请输入正确的邮箱'
                        }]}>
                            <ETInput type="email" placeholder="邮箱"/>
                        </Form.Item>
                        <Form.Item label="密码" name="password" rules={[{
                            required: true,
                            message: '请输入密码'
                        }, {
                            pattern: PasswordReg,
                            message: '请输入正确的密码'
                        }]}>
                            <ETInput type="password" placeholder="密码 [6-18位大小写字母、数字]"/>
                        </Form.Item>
                        <Form.Item label="验证码" name="code" rules={[
                            {
                                required: true,
                                message: '请输入验证码'
                            }
                        ]}>
                            <ETCaptcha
                                onCaptcha={async () => {
                                    const email = form.getFieldValue('email');
                                    if (!email) {
                                        message.error('请输入邮箱');
                                        throw new Error('请输入邮箱');
                                    }
                                    await handleCaptcha(form.getFieldValue('email'));
                                }}
                                onChange={({target: {value}}) => {
                                    form.setFieldValue('code', value);
                                }}
                            />
                        </Form.Item>
                        <Flex justify="space-between">
                            <Button
                                onClick={() => setTabKey('login')}
                                className={styles['center-btn']}
                                type="text">
                                <ArrowLeftOutlined/>
                                去登录
                            </Button>
                        </Flex>
                        <Flex justify="center">
                            <Button htmlType="submit" className={styles['form-btn']} type="primary">
                                注册并登录
                            </Button>
                        </Flex>
                    </Form>
                </div>
            </Flex>
            {
                authType && thirdAuth &&
                <ThirdAuth open={thirdAuth} type={authType} onClose={() => setThirdAuth(false)}/>
            }
            <ForgotPWD open={forgotPwd} onClose={() => setForgotPwd(false)}/>
        </div>
    )
}

export default Auth;
