import React, {useEffect, useState} from 'react';
import styles from './index.module.less';
import {FullscreenExitOutlined, FullscreenOutlined, LeftOutlined} from "@ant-design/icons";
import {Flex} from "antd";
import EternalIcon from "@/components/icon";
import {toggleFullscreen} from "@/utils/common-utils";
import {useMusicStore} from "@/store/music";

const PlayerHeader: React.FC = () => {
    const [fullscreen, setFullscreen] = useState(false);
    const setOpenPlayer = useMusicStore(s => s.setOpenPlayer);

    useEffect(() => {
        const listenFullscreen = () => {
            console.log(document.fullscreenElement != null)
            setFullscreen(document.fullscreenElement != null);
        }
        document.addEventListener('fullscreenchange', listenFullscreen);
        return () => {
            document.removeEventListener('fullscreenchange', listenFullscreen);
        }
    }, [])

    return (
        <div className={styles['player-header']}>
            <button onClick={() => setOpenPlayer(false)} className={styles.btn}>
                <LeftOutlined className={styles.icon}/> 返回
            </button>
            <Flex gap={12} style={{marginLeft: 'auto'}}>
                <button onClick={() => toggleFullscreen()} className={styles.btn}>
                    {
                        fullscreen ? <FullscreenExitOutlined className={styles.icon}/> :
                            <FullscreenOutlined className={styles.icon}/>
                    }
                </button>
                <button className={styles.btn}>
                    <EternalIcon className={styles.icon} type="icon-xiazai-xue"/>
                </button>
            </Flex>
        </div>
    )
}

export default PlayerHeader;
