import Api from "@/api";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {API} from "@/service/types";
import CacheUtils from "@/utils/cache-utils";
import {CACHE_KEY, MessageCode} from "@/constant";

class RetryError extends Error {
}

const conversation = async (ctrl: AbortController,
                            params: API.ConversationReq,
                            onMessage: (data: API.ConversationRes) => void,
                            onFinish: () => void,
) => {
    const token = CacheUtils.get<string>(CACHE_KEY.TOKEN);
    await fetchEventSource(Api.defaults.baseURL + '/chat/stream', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
            Authorization: token as string,
        },
        openWhenHidden: true,
        body: JSON.stringify(params),
        signal: ctrl.signal,
        onmessage: (event) => {
            const data = event.data;
            if (data === '[DONE]') {
                return;
            }
            try {
                const res = JSON.parse(data);
                if (res?.code === MessageCode.ERROR) {
                } else {
                    onMessage(res);
                }
            } catch (e) {
                console.warn(e);
                if (e instanceof RetryError) {
                    throw e;
                }
            }
        },
        onclose: () => {
            onFinish();
        },
        onerror: (err) => {
            console.log(err);
            return 2000;
        }
    });
}

const getTitle = (cid: string) => {
    return Api.get('/chat/getTitle?cid=' + cid);
}

const chatList = async (): Promise<API.ChatListRes> => {
    return Api.get('/chat/records');
}

const chatRecord = async (conversationId: string): Promise<API.ChatRecordRes> => {
    return Api.get('/chat/detail', {
        params: {
            cid: conversationId
        }
    });
}

const uploadFile = (file: File, sid: number): Promise<API.UploadFileRes> => {
    const form = new FormData();
    form.append('file', file, file.name);
    form.append('sid', String(sid));
    return Api.post('/chat/upload', form, {
        headers: {
            "Content-Type": "multipart/form-data; charset=UTF-8"
        }
    });
}

const getServerList = (): Promise<API.ServerListRes> => {
    return Api.get('/chat/serverList');
}

const deleteChat = (delObj: object[]): Promise<API.Result<any>> => {
    return Api.post('/chat/delete', delObj);
}

const initWxQrCode = (): Promise<API.Result<API.WXQrcodeData>> => {
    return Api.get('/wx/initId');
}

const pollWxLogin = (accessId: string): Promise<API.LoginRes> => {
    return Api.post('/wx/pollLogin', {}, {
        params: {
            accessId
        }
    });
}

const initQqQrCode = (): Promise<API.Result<API.WXQrcodeData>> => {
    return Api.get('/qq/initId');
}

const pollQqLogin = (accessId: string): Promise<API.LoginRes> => {
    return Api.post('/qq/pollLogin', {}, {
        params: {
            accessId
        }
    });
}


export const HubService = {
    conversation,
    chatList,
    chatRecord,
    uploadFile,
    getServerList,
    getTitle,
    deleteChat,
    initWxQrCode,
    pollWxLogin,
    initQqQrCode,
    pollQqLogin
}
