import React, {useEffect, useMemo, useState} from 'react';
import {App, Avatar, Badge, Button, Dropdown, Flex, MenuProps, Popover, Space} from "antd";

import styles from './index.module.less';
import {DownOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import Lottie from 'react-lottie';
import {useMenuStore} from "@/store/menu";
import menu from '@/assets/menu.json';
import notice from '@/assets/notice.json';
import {useAuthStore} from "@/store/auth";
import CacheUtils from "@/utils/cache-utils";
import {CACHE_KEY} from "@/constant";
import {UserService} from "@/service/user-service";
import Notice from "@/components/header/notice";
import {API} from "@/service/types";
import {MessageService} from "@/service/message";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";
import remarkMath from "remark-math";
import rehypeHighlight from "rehype-highlight";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import CodeComponent from "@/pages/home/chat/components/markdown-ext/code-component";
import LinkComponent from "@/pages/home/chat/components/markdown-ext/link-component";
import '@/style.less';

const Header: React.FC = () => {
    const {collapsed, setCollapsed} = useMenuStore();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const {userInfo} = useAuthStore();
    const [notices, setNotices] = useState<API.NoticeItem[]>([]);
    const {notification} = App.useApp();
    const updateNotice = async () => {
        const res = await MessageService.getMessage();
        if (res.code === 1000) {
            setNotices(res.data);
        }
    }

    const showNotice = (item: API.NoticeItem) => {
        notification.info({
            message: item.title,
            description: <ReactMarkdown
                className={"md-theme"}
                children={item?.content}
                remarkPlugins={
                    [
                        remarkGfm,
                        remarkEmoji,
                        remarkMath]}
                rehypePlugins={
                    [
                        rehypeHighlight,
                        rehypeKatex,
                        rehypeRaw,
                    ]
                }
                components={{
                    code: CodeComponent,
                    a: LinkComponent,
                }}
            />
        });
    }

    useEffect(() => {
        if (userInfo?.uid) {
            MessageService.subscribe(String(userInfo?.uid), (data: any) => {
                updateNotice();
                showNotice(data);
            });
        }
    }, [userInfo]);

    useEffect(() => {
        updateNotice();
    }, []);

    const unReadItems = useMemo(() => {
        return notices?.filter(item => item.isRead === 0);
    }, [notices])

    const items: MenuProps['items'] = [
        {
            key: 'logout',
            label: '退出登录',
            icon: <LogoutOutlined/>,
            onClick: () => {
                UserService.logout().then(r => {
                    CacheUtils.remove(CACHE_KEY.TOKEN);
                    window.location.href = '/auth';
                });
            }
        },
        {
            key: 'person',
            label: '个人中心',
            icon: <UserOutlined/>,
            onClick: () => {
                window.open('/user/info');
            },
            disabled: true,
        }
    ];


    return (
        <Flex className={styles.header} align="center" justify="end">
            <Button
                className={styles.menu} type="text"
                onClick={() => setCollapsed(!collapsed)}>
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: menu
                    }}
                    width={20}
                    height={20}
                    direction={collapsed ? -1 : 1}
                    speed={1.5}
                />
            </Button>
            {/*TODO: 通知*/}
            <Popover  content={<Notice onRead={updateNotice} notices={notices}/>} title="通知列表">
                <Badge size="small" dot={unReadItems.length > 0} className={styles.notice}
                       status="processing"
                >
                    <Button shape="circle" type="text">
                        <Lottie
                            options={{
                                loop: unReadItems?.length > 0,
                                autoplay: unReadItems?.length > 0,
                                animationData: notice,
                            }}
                            isPaused={unReadItems?.length <= 0}
                            isClickToPauseDisabled={true}
                            width={24}
                            height={24}
                            direction={1}
                        />
                    </Button>
                </Badge>
            </Popover>
            <Dropdown menu={{items}}>
                <Space>
                    <Avatar
                        src={userInfo?.avatar}
                        className={styles.avatar}>
                    </Avatar>
                    {userInfo?.nickname?.slice(0, 2)}
                    {/* @ts-ignore*/}
                    {userInfo?.nickname?.length > 2 && '...'}
                    <DownOutlined/>
                </Space>
            </Dropdown>
            {/*TODO: 助手*/}
            {/*<Button*/}
            {/*    onClick={() => setDrawerOpen(true)}*/}
            {/*    type="text" style={{padding: 0, marginLeft: 24, width: 24, height: 24}}>*/}
            {/*    <Lottie*/}
            {/*        options={{*/}
            {/*            loop: true,*/}
            {/*            autoplay: true,*/}
            {/*            animationData: grid*/}
            {/*        }}*/}
            {/*        isClickToPauseDisabled={true}*/}
            {/*        width={24}*/}
            {/*        height={24}*/}
            {/*        direction={1}*/}
            {/*    />*/}
            {/*</Button>*/}
            {/*<Drawer*/}
            {/*    onClose={() => setDrawerOpen(false)}*/}
            {/*    placement="right"*/}
            {/*    classNames={{*/}
            {/*        content: styles['drawer-content'],*/}
            {/*        mask: styles['drawer-mask']*/}
            {/*    }}*/}
            {/*    height="100vh"*/}
            {/*    width="640px"*/}
            {/*    loading={true}*/}
            {/*    title={*/}
            {/*        <>*/}
            {/*            智能角色*/}
            {/*            <Divider type="vertical"/>*/}
            {/*            <span style={{fontSize: 12, color: '#9aa0b4', fontWeight: 400}}>*/}
            {/*                内置/个人创建的智能预置提醒,给AI赋予初始的角色*/}
            {/*            </span>*/}
            {/*        </>}*/}
            {/*    open={drawerOpen}*/}
            {/*>*/}

            {/*</Drawer>*/}
        </Flex>
    )
}

export default Header;
