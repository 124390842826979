export enum CACHE_KEY {
    TOKEN = '_t',
    CHECK_SERVER = 'check_server',
    PARAMS = 'params',
    LAST_CHAT_CONTENT = 'last_chat_content',
}

export const UserNameReg: RegExp = /^[a-zA-Z0-9_-]{4,12}$/;
export const PasswordReg: RegExp = /^[a-zA-Z0-9_-]{6,18}$/;
export const PhoneReg: RegExp = /^1[3-9]\d{9}$/;

export enum MailCodeType {
    Register = 0,
    FindPassword = 1
}

export enum MessageCode {
    SUCCESS = 1000,
    ERROR = 1001,
    UNAUTHORIZED = 1002
}

export enum Role {
    USER = "user",
    SYSTEM = "system",
    ASSISTANT = "assistant"
}

export enum TextType {
    TEXT = "text",
    IMAGE = "image",
    FILE = "file",
    WEB = "web",
    PLUGIN = "plugin",
    TOOL_CALLS = 'tool_calls',
    REFERENCE_LINK = 'referenceLink',
    TEXT_TO_IMAGE = "text2image",
    REFERENCE_PART = "referencePart",
}

export enum Status {
    WAITING = "waiting",
    GENERATING = "generating",
    FINISHED = "finished",
    // 绘画中
    DRAWING = "drawing",
    // 查询中
    QUERYING = "querying",
}

export enum ServerAuthState {
    unauthorized = 'unauthorized',
    authorized = 'authorized',
    error = 'error'
}

// 参考 chatgpt 4o 的格式
export const USER_REFERENCE_PREFIX = "The user is referring to this in particular:";

export enum WOKER_EVENT_NAMES {
    getMainColor = 'getMainColor'
}

