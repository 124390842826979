import React, {useEffect} from 'react';
import styles from './index.module.less';
import {Button, Drawer, Flex, Input, Space, Tag, Tooltip} from "antd";
import {useMusicStore} from "@/store/music";
import ly from '@/assets/ly';
import {AudioStore} from "@/store/music/audio-store";

import {FormOutlined, QuestionCircleOutlined, SignatureOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Player from "@/pages/home/music/components/player";
import {createPortal} from "react-dom";
import EternalIcon from "@/components/icon";
import MusicItem from "@/pages/home/music/components/music-item";
import TopPlayer from "@/pages/home/music/components/top-player";
import {LYR_TIP} from "@/constant/music";
import {useNavigate} from "react-router-dom";

const styleTags = [
    {
        label: '男歌手',
        color: 'purple',
        value: 'male singer',
        icon: 'icon-nansheng'
    },
    {
        label: '女歌手',
        color: 'orange',
        value: 'female singer',
        icon: 'icon-nv'
    },
    {
        label: '悲伤',
        color: 'magenta',
        value: 'sad',
        icon: ''
    },
    {
        label: '流行',
        color: 'red',
        value: 'pop',
        icon: ''
    },
    {
        label: '轻快',
        color: 'volcano',
        value: 'light',
        icon: ''
    },
    {
        label: '钢琴',
        color: 'orange',
        value: 'piano',
        icon: ''
    },
    {
        label: '电子',
        color: 'gold',
        value: 'electro',
        icon: ''
    },
    {
        label: '摇滚',
        color: 'lime',
        value: 'rock',
        icon: ''
    },
    {
        label: '吉他',
        color: 'green',
        value: 'guitar',
        icon: ''
    },
    {
        label: '歌剧',
        color: 'cyan',
        value: 'opera',
        icon: ''
    },
    {
        label: '民谣',
        color: 'blue',
        value: 'folk',
        icon: ''
    },
    {
        label: '古典',
        color: 'geekblue',
        value: 'classical',
        icon: ''
    }
];
const Music: React.FC = () => {
    useNavigate()("/")
    const {
        setCoverSrc,
        setLycInfo,
        setPlayState,
        setDuration,
        setPlaybackRate,
        setCurrentTime,
        setVolume,
        setCanplay,
        setOpenPlayer,
        openPlayer
    } = useMusicStore();

    useEffect(() => {
        setCoverSrc('https://img0.baidu.com/it/u=3714848999,3288698975&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800');
        setLycInfo(ly);
    }, [setLycInfo, setCoverSrc, setPlayState]);

    useEffect(() => {
        AudioStore.getInstance()
            .setSrc('https://sharefs.tx.kugou.com/202408242140/b7fd4b0dbe7b17f2c23e584ecd05df11/v3/8cf459cfcb3070055e0b91de4a5bfb68/yp/full/ap1000_us0_pi409_s208860103.mp3')
            .onStatusChange(setPlayState)
            .onDurationChange(setDuration)
            .onPlaybackRateChange(setPlaybackRate)
            .onTimeUpdate(setCurrentTime)
            .onVolumeChange(setVolume)
            .onCanPlay(setCanplay);
    }, [setCanplay, setCurrentTime, setDuration, setPlayState, setPlaybackRate, setVolume]);

    return (
        <div className={styles.wrapper}>
            <TopPlayer/>
            <Flex vertical className={styles.left}
                  justify="center"
            >
                <div className={styles.header}>
                    <Flex>
                        <span className={styles.label}>
                           标题
                        </span>
                        <Input placeholder="请输入歌名" className={styles.input}/>
                    </Flex>
                </div>
                <p className={styles['text-area-label']}>歌词
                    <Tooltip placement="right"
                             title={<>前奏:Intro，主歌:Verse，副歌:Chorus，桥段:Bridge，尾奏:Outro
                                 <div className={styles['text-area-tip-tooltip']}>
                                     {LYR_TIP}
                                 </div>
                             </>}>
                        <QuestionCircleOutlined style={{marginLeft: 8}}/>
                    </Tooltip>
                </p>
                <p className={styles['text-area-tip']}>规范的歌词格式生成的音乐会更好，可以参考上述示例，也可以直接使用歌词修正</p>
                <TextArea placeholder="可以输入关键词用ai生成完整歌词" autoSize={false} className={styles.textArea}/>
                <Button type="primary" className={styles['gen-btn']} shape="round">
                    <SignatureOutlined/>
                    AI 生成歌词
                </Button>
            </Flex>
            <Flex vertical className={styles.right}>
                <p>歌曲风格</p>
                <TextArea placeholder="可以输入任何词语优化你的音乐，逗号分隔，如：电子，轻快，吉他" autoSize={false} className={styles.textArea}/>
                <Flex style={{marginTop: 8}}>
                    <Space wrap={true} size={6}>
                        <p style={{whiteSpace: 'nowrap'}}>示例风格</p>
                        {
                            styleTags.map(item => (
                                <Tag
                                    icon={item.icon && <EternalIcon type={item.icon}/>}
                                    key={item.value}
                                    style={{marginRight: 0, cursor: 'pointer', fontSize: 13}}
                                    bordered={false}
                                    color={item.color}>{item.label}</Tag>))
                        }
                    </Space>
                </Flex>
                <Flex justify="start" style={{margin: '8px 0'}} gap={8}>
                    <Button type="primary">
                        <FormOutlined/>
                        开始创作</Button>
                    <Button type="default" onClick={() => setOpenPlayer(true)}>
                        <EternalIcon type="icon-a-discbox"/>
                        播放器
                    </Button>
                </Flex>
                <Flex className={styles['music-list']} vertical gap={8}>
                    {
                        Array.from({length: 10}).map((_, index) => (
                            <MusicItem key={index}/>
                        ))
                    }
                </Flex>
            </Flex>

            {
                createPortal(
                    <Drawer
                        bodyStyle={{padding: 0, overflow: 'hidden'}}
                        closeIcon={false}
                        height={'100vh'}
                        open={openPlayer}
                        placement="bottom">
                        <Player/>
                    </Drawer>,
                    document.body)
            }
        </div>
    )
}

export default Music;
