import React from 'react';
import {API} from "@/service/types";
import styles from './index.module.less';
import {Button, Flex} from "antd";
import {DownloadOutlined, PlayCircleOutlined, ShareAltOutlined} from "@ant-design/icons";
import EternalIcon from "@/components/icon";

interface MusicItemProps {
    music?: API.MusicItem
}

const MusicItem: React.FC<MusicItemProps> = ({music}) => {
    const [like, setLike] = React.useState(false);
    return (
        <div className={styles['music-item']}>
            <div className={styles.cover}>
                <img alt=""
                     src="https://gd-hbimg.huaban.com/74880d36470c43f3d359a3d5ac60b5b3464253a5850ce-rtGXyZ"/>
                <div className={styles.mask}><PlayCircleOutlined/></div>
            </div>
            <Flex vertical>
                <p className={styles.title}>这是歌名</p>
                <p className={styles.tags}>scsc,发分，生成式</p>
                <p className={styles.time}>20:88</p>
            </Flex>
            <Flex align="center" gap={8} style={{marginLeft: 'auto'}}>
                <Button onClick={() => setLike(!like)} type="text" shape="circle">
                    <EternalIcon type={like ? 'icon-like' : 'icon-line-like'}/>
                </Button>
                <Button type="text" shape="circle"><DownloadOutlined/></Button>
                <Button type="text" shape="circle"><ShareAltOutlined/></Button>
            </Flex>
        </div>
    )
}

export default React.memo(MusicItem);
