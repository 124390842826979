import {App, Avatar, Button, Divider, Flex, FloatButton, Space, Tag, Tooltip} from "antd";
import {
    ArrowDownOutlined,
    CloseCircleOutlined,
    ClusterOutlined,
    FieldTimeOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    LinkOutlined,
    PauseOutlined,
    SendOutlined
} from "@ant-design/icons";
import styles from './index.module.less';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {HubService} from "@/service/hub-service";
import {useNavigate, useSearchParams} from "react-router-dom";
import {v4} from 'uuid';
import {CACHE_KEY, Role, Status, TextType, USER_REFERENCE_PREFIX} from "@/constant";
import {API} from "@/service/types";
import {useLocalStorageState, useMount, useThrottleFn} from "ahooks";
import {getQueryParams, isImageFile, scrollToBottom} from "@/utils/common-utils";
import responseUtils from "@/utils/response-utils";
import useFileSelect from "@/hooks/useFileSelect";
import FileItem from "@/pages/home/chat/components/markdown-ext/file-item";
import {useChatStore} from "@/store/chat";
import {TempContentSingleInstance} from "@/store/vstore";
import ChatItem from "@/pages/home/chat/components/chat-item";
import ChatTemp from "@/pages/home/chat/components/chat-temp";
import ModelPicker, {ModelPickerRef} from "@/pages/home/chat/components/model-picker";
import EternalIcon from "@/components/icon";

const Chat: React.FC = () => {
    const [inputFull, setInputFull] = useState(false);
    const [value, setValue] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [searchParams] = useSearchParams();
    const cid = useRef<string>(getQueryParams()?.cid || "")
    const abortController = useRef<AbortController>(new AbortController());
    const pageRef = useRef<HTMLDivElement | null>(null);
    const [isAutoScroll, setIsAutoScroll] = useState(true);
    const {run: throttleScrollToBottom} = useThrottleFn(scrollToBottom, {wait: 100});
    const [isFirst, setIsFirst] = useState(true);
    const modelPickerRef = useRef<ModelPickerRef | undefined>(undefined);
    const [selectSever] = useLocalStorageState<API.ServerItem>(CACHE_KEY.CHECK_SERVER, {
        listenStorageChange: true,
    });
    const {setVisible} = useChatStore(s => ({
        setVisible: s.setOpenList,
    }));
    useEffect(() => {
        cid.current = getQueryParams()?.cid || "";
    }, [searchParams]);
    const {
        addContent,
        clearContents,
        contents,
        finished,
        setFinished,
        text,
        resetSelected,
    } = useChatStore(s => ({
        addContent: s.addContent,
        clearContents: s.clearContents,
        contents: s.contents,
        finished: s.finished,
        setFinished: s.setFinished,
        text: s.selectedText,
        resetSelected: s.resetSelected,
    }));

    useEffect(() => {
        if (pageRef.current) {
            const handle = (e: any) => {
                const {scrollTop, scrollHeight, clientHeight} = e.target;
                TempContentSingleInstance.isAutoScroll = scrollTop + clientHeight >= scrollHeight - 40;
                setIsAutoScroll(TempContentSingleInstance.isAutoScroll);
            };
            pageRef.current.addEventListener('scroll', handle)
            return () => {
                pageRef.current?.removeEventListener('scroll', handle);
            }
        }
    }, [contents]);
    const {selectedFiles, removeFile, fileInputElement, triggerFileInput} = useFileSelect({
        multiple: true,
        maxSize: 1024 * 1024 * 20,
        maxFiles: 10,
        accept: selectSever?.fileType?.split('|').map(item => `.${item}`).join(',')
    });
    const [disabledRemove, setDisabledRemove] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState<Record<string, boolean>>({});

    const {message} = App.useApp();
    const navigate = useNavigate();

    const resetContent = useCallback(() => {
        setFinished(true);
        setValue('');
        setInputFull(false);
        clearContents();
    }, [clearContents, setFinished]);

    const initRecords = useCallback(async () => {
        resetContent();
        if (!cid.current) return;
        const {success, error} = await responseUtils(HubService.chatRecord, cid.current);
        success(data => {
            data?.sort((a, b) => {
                return a.part.createTime - b.part.createTime;
            });
            data?.forEach(item => {
                addContent({
                    conversationId: cid.current,
                    part: item.part,
                    plugins: item.plugins
                });
            });
        });
        error(() => {
            createNewChat();
        });
        setTimeout(() => {
            pageRef.current && throttleScrollToBottom(pageRef.current);
        }, 300);
    }, [resetContent, addContent, throttleScrollToBottom, searchParams]);

    useEffect(() => {
        initRecords();
    }, [initRecords]);

    const onMessage = (data: API.ConversationRes) => {
        TempContentSingleInstance.setState(data);
        TempContentSingleInstance.isAutoScroll && pageRef.current && throttleScrollToBottom(pageRef.current);
    }

    const onFinished = () => {
        setFinished(true);
        setDisabledRemove(false);
        addContent(TempContentSingleInstance.getTempContent()!);
        if (selectSever?.sid! > 1 && isFirst) {
            HubService.getTitle(cid.current);
            setIsFirst(false);
        }
        TempContentSingleInstance.setState(null);
    }

    const createNewChat = () => {
        if (!cid.current) {
            return message.info('当前已是最新对话', 0.5);
        }
        resetContent();
        cid.current = '';
        setIsFirst(true);
        navigate(`/home/chat`, {replace: true});
    }

    const handlePause = () => {
        setFinished(true);
        abortController?.current?.abort();
    }

    const handleUpload = async () => {
        // 添加到队列
        let feedbackFiles: any[] = [];
        for (const file of selectedFiles) {
            setUploadingFiles(pre => {
                const newObj = {...pre};
                newObj[file.name] = true;
                return newObj;
            });
            const {success} = await responseUtils(HubService.uploadFile, file, selectSever?.sid!);
            success((data) => {
                data.fileType = isImageFile(file) ? TextType.IMAGE : TextType.FILE;
                feedbackFiles.push(data);
            });
            setUploadingFiles(pre => {
                const newObj = {...pre};
                newObj[file.name] = false;
                return newObj;
            });
            removeFile(file);
        }
        return feedbackFiles;
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleConversation();
        }
    }

    useMount(() => {
        const t = setTimeout(() => {
            pageRef.current && throttleScrollToBottom(pageRef.current);
        }, 500);
        return () => {
            clearTimeout(t);
        }
    });

    const handleConversation = async () => {
        if (!value.trim()) {
            return;
        }
        let id = cid.current;
        if (!id) {
            id = v4();
            cid.current = id;
            window.history.replaceState({}, '', `/home/chat?cid=${id}`);
        }
        setDisabledRemove(true);

        let feedbackFiles = [];
        if (selectedFiles.length) {
            feedbackFiles = await handleUpload();
        }

        setFinished(false);
        TempContentSingleInstance.setState({
            conversationId: id,
            part: {
                id: v4(),
                type: TextType.TEXT,
                text: '请稍等',
                role: Role.ASSISTANT,
                status: Status.WAITING,
                createTime: Date.now(),
            }
        });

        let sendContents: API.Content[] = [
            {
                type: TextType.TEXT,
                text: value,
                role: Role.USER
            }
        ];
        let userPlugins: API.PluginType[] = [];
        if (feedbackFiles.length > 0) {
            feedbackFiles.forEach(file => {
                sendContents.push({
                    type: file.fileType!,
                    text: file.url,
                    role: Role.USER
                });
                userPlugins.push({
                    type: file.fileType,
                    url: file.url,
                    fileName: file.fileName,
                    fileSize: file.fileSize,
                });
            });
        }

        if (text) {
            sendContents.push({
                type: TextType.TEXT,
                text: USER_REFERENCE_PREFIX + text,
                role: Role.SYSTEM
            });
            userPlugins.push({
                type: TextType.REFERENCE_PART,
                referencePart: {
                    id: v4(),
                    createTime: Date.now(),
                    type: TextType.TEXT,
                    text: text,
                    role: Role.SYSTEM,
                    status: Status.FINISHED,
                }
            });
            resetSelected();
        }

        addContent({
            conversationId: id,
            part: {
                id: v4(),
                type: TextType.TEXT,
                text: value,
                role: Role.USER,
                status: Status.FINISHED,
                createTime: Date.now(),
            },
            plugins: userPlugins
        });
        setTimeout(() => {
            pageRef.current && scrollToBottom(pageRef.current);
        }, 60);
        HubService.conversation(abortController?.current,
            {
                sid: selectSever?.sid || 2,
                cid: id,
                contents: sendContents
            },
            onMessage,
            onFinished
        );
        setValue('');
    }

    const onEdit = (text: string) => {
        setValue(text);
        textareaRef.current?.focus();
    }

    return (
        <>
            {fileInputElement}
            <Flex justify="space-between" align="center" className={styles.picker}>
                <Tooltip title="创建新对话">
                    <Button onClick={createNewChat} type="primary" shape="circle">
                        <EternalIcon type="icon-kaiqixinduihua"/>
                    </Button>
                </Tooltip>
                <Button type="text" shape="circle" onClick={() => setVisible(true)}>
                    <FieldTimeOutlined style={{fontSize: 18}}/>
                </Button>
                <Tooltip title={(!!searchParams.get('cid') || !!cid.current) ? '新对话选择模型' : '切换模型'}>
                    <Button disabled={!!searchParams.get('cid') || !!cid.current} onClick={() => {
                        if (getQueryParams()?.cid) {
                            return message.info('创建新对话选择模型', 0.5);
                        }
                        modelPickerRef.current?.show()
                    }}
                            type="text"
                            shape="circle"
                    >
                        <ClusterOutlined style={{fontSize: 18}}/>
                    </Button>
                </Tooltip>
                <Divider type="vertical" style={{borderWidth: 1, borderColor: '#95a1c7'}}/>
                <Avatar src={selectSever?.icon}>
                    {selectSever?.name.slice(0, 1)}
                </Avatar>
            </Flex>
            <Flex style={{padding: 8}}>
                <div className={styles.content} ref={pageRef}>
                    {
                        contents?.map(c =>
                            <ChatItem
                                onEdit={onEdit}
                                key={c?.part?.id + c.part.role}
                                content={c}
                            />
                        )
                    }
                    <ChatTemp/>
                </div>
            </Flex>
            <Flex className={styles.input} gap={8}>
                <Space size={2}>
                    <Button disabled={disabledRemove || (!selectSever?.fileType || selectSever?.fileType?.length === 0)}
                            onClick={triggerFileInput}
                            type="primary"
                            shape="circle"><LinkOutlined/></Button>
                </Space>
                <div className={styles['textarea-wrap']}>
                    <Flex style={{padding: 8, maxHeight: 140, overflow: 'auto'}} wrap gap={8}>
                        {
                            selectedFiles?.map(file => {
                                return <FileItem
                                    isUploading={uploadingFiles[file.name]}
                                    disabled={disabledRemove}
                                    onRemove={removeFile}
                                    file={file}
                                    key={file.name}/>;
                            })
                        }
                    </Flex>
                    {
                        text &&
                        <Flex className={styles['textarea-ref']}>
                            <CloseCircleOutlined onClick={() => resetSelected()} style={{marginRight: 8, height: 24}}/>
                            <Tag style={{height: 24}} bordered={false} color="green">@ AI助手</Tag>
                            {text}
                        </Flex>
                    }
                    <textarea
                        onKeyDown={handleKeyUp}
                        placeholder='【 Enter 】发送消息，【 Shift+Enter 】换行'
                        ref={textareaRef}
                        value={value}
                        onChange={({target: {value: v}}) => setValue(v)}
                        style={{height: inputFull ? '70vh' : '64px'}}
                        className={styles.textarea}/>
                </div>
                <Space size={8}>
                    <Button
                        type="primary"
                        shape="circle"
                        onClick={() => setInputFull(!inputFull)}
                    >{
                        inputFull ? <FullscreenExitOutlined/> : <FullscreenOutlined/>
                    }</Button>
                    {
                        finished ?
                            <Button
                                disabled={!value.trim() || disabledRemove}
                                type="primary"
                                onClick={handleConversation} shape="round">
                                <SendOutlined/>
                            </Button> :
                            <Button
                                type="primary"
                                onClick={handlePause}
                                shape="round">
                                <PauseOutlined/>
                            </Button>
                    }
                </Space>
            </Flex>
            {
                !isAutoScroll &&
                <FloatButton
                    onClick={() => pageRef.current && scrollToBottom(pageRef.current)}
                    icon={<ArrowDownOutlined/>}
                />
            }
            <ModelPicker refAction={modelPickerRef}/>
        </>
    )
}

export default Chat;
