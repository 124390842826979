import React, {useEffect, useState} from 'react';
import {Modal} from "antd";
import styles from './index.module.less';
import responseUtils from "@/utils/response-utils";
import {HubService} from "@/service/hub-service";
import CacheUtils from "@/utils/cache-utils";
import {CACHE_KEY} from "@/constant";
import {useAuthStore} from "@/store/auth";
import {useNavigate} from "react-router-dom";

export enum AuthType {
    QQ = 'qq',
    WECHAT = 'wechat',
}

export const NameMap = {
    [AuthType.QQ]: '请使用QQ扫码登录',
    [AuthType.WECHAT]: '请使用微信扫码登录',
}

interface Props {
    open: boolean;
    type: AuthType;
    onClose: () => void;
}

const ThirdAuth: React.FC<Props> = ({open, type, onClose}) => {
    const [qrcode, setQrcode] = useState('');
    const [accessId, setAccessId] = useState('');
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
    const {setUserInfo} = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQrCode = async () => {
            setLoading(true);
            const {success} = await responseUtils(type === AuthType.QQ ? HubService.initQqQrCode : HubService.initWxQrCode);
            success(data => {
                setAccessId(data?.accessId);
                setQrcode(data?.qrcode);
            });
            setLoading(false);
        };

        fetchQrCode();
    }, [type]);

    useEffect(() => {
        if (accessId && timer === null) {
            const newTimer = setInterval(async () => {
                const {success} = await responseUtils(type === AuthType.QQ ? HubService.pollQqLogin : HubService.pollWxLogin, accessId);
                success(data => {
                    if (data?.token) {
                        clearInterval(newTimer);
                        CacheUtils.set<string>(CACHE_KEY.TOKEN, data?.token);
                        setUserInfo(data.userInfo);
                        // 到主页
                        navigate('/');
                    }
                });
            }, 2000);
            setTimer(newTimer);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [accessId, navigate, setUserInfo, timer, type]);

    return (
        <Modal
            loading={loading}
            className={styles.modal}
            width={320}
            height={300}
            footer={null}
            destroyOnClose
            title={
                <>
                    登录 &nbsp;
                    <span className="sub-title">{NameMap[type]}</span>
                </>
            }
            open={open}
            onCancel={onClose}
        >
            <div>
                <img alt="qrcode" className={styles.qrcode} src={qrcode}/>
            </div>
        </Modal>
    );
}

export default ThirdAuth;
