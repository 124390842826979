import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface MenuStore {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
}

export const useMenuStore = create(persist<MenuStore>(
    (set) => ({
        collapsed: false,
        setCollapsed: (collapsed: boolean) => set({collapsed}),
    }),
    {
        name: 'menu-storage', // 用于 localStorage 的键名
        getStorage: () => localStorage, // 你可以选择使用 sessionStorage 或 localStorage
    }
));
