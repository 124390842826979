let colorCache: Record<string, string[]> = {};

export class ColorUtils {
    static lightenRGB(rgb: string, amount: number): string {
        if (!rgb) return rgb;
        // 将rgb(r,g,b)转为number[]
        const rgbArray = rgb.replace(/[^\d,]/g, '').split(',').map(Number);
        // 将 RGB 颜色值转换为 HSL 颜色值
        const hsl = this.rgbToHsl(rgbArray);

        // 增加 HSL 颜色值的亮度（L）部分
        hsl[2] = Math.min(1, hsl[2] + amount);

        // 将修改后的 HSL 颜色值转换回 RGB 颜色值
        const newArray = this.hslToRgb(hsl);
        // number[] 转为 rgb(r,g,b)
        return `rgb(${newArray.join(',')})`;
    }

    static rgbToHsl(rgb: number[]): number[] {
        const r = rgb[0] / 255;
        const g = rgb[1] / 255;
        const b = rgb[2] / 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        const l = (max + min) / 2;

        if (max === min) {
            return [0, 0, l];
        }

        const d = max - min;
        const s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        let h = 0;
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        return [h / 6, s, l];
    }

    static hslToRgb(hsl: number[]): number[] {
        const h = hsl[0];
        const s = hsl[1];
        const l = hsl[2];

        if (s === 0) {
            return [l * 255, l * 255, l * 255];
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        const r = this.hueToRgb(p, q, h + 1 / 3);
        const g = this.hueToRgb(p, q, h);
        const b = this.hueToRgb(p, q, h - 1 / 3);

        return [r * 255, g * 255, b * 255];
    }

    static hueToRgb(p: number, q: number, t: number): number {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }


    static getImageMainColor(src: string, count: number): Promise<string[]> {
        return new Promise((resolve, reject) => {
            console.log(colorCache)
            if (colorCache[src]) {
                resolve(colorCache[src]);
                return;
            }
            const img = new Image();
            img.src = src;
            img.crossOrigin = 'Anonymous';

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (!ctx) {
                    reject(new Error('Failed to get canvas context'));
                    return;
                }

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;
                if (!data) {
                    resolve([]);
                    return;
                }

                const colorMap: Record<string, number> = {};

                for (let i = 0; i < data.length; i += 4) {
                    const r = data[i];
                    const g = data[i + 1];
                    const b = data[i + 2];
                    // 计算平均亮度
                    const brightness = Math.round(((parseInt(String(r), 10) + parseInt(String(g), 10) + parseInt(String(b), 10)) / 3) / 255 * 100);
                    // 设定亮度阈值，例如介于20%到80%之间
                    if (brightness >= 20 && brightness <= 80) {
                        const rgb = `rgb(${r}, ${g}, ${b})`;
                        colorMap[rgb] = (colorMap[rgb] || 0) + 1;
                    }
                }

                const result = Object.entries(colorMap)
                    .sort((a, b) => b[1] - a[1])
                    .map(([color, _]) => color)
                    .slice(0, count);
                colorCache[src] = result;
                resolve(result);
            };

            img.onerror = () => {
                resolve(['rgb(150,150,150)']);
            };
        });
    }

    static adjustToTargetBrightness(rgbString: string, targetBrightness: number = 150): string {
        // 移除可能存在的 'rgb(' 和 ')' 并分割成数组
        const values = rgbString.match(/\d+/g)?.map(Number) || [0, 0, 0];

        // 计算当前亮度的平均值
        const currentAverage = values.reduce((acc, val) => acc + val, 0) / values.length;

        // 计算调整因子
        const adjustmentFactor = targetBrightness / currentAverage;

        // 调整RGB值
        const adjustedValues = values.map(value => {
            // 应用调整因子，并限制在 [0, 255] 范围内
            return Math.min(255, Math.max(0, Math.round(value * adjustmentFactor)));
        });

        // 重新构造颜色字符串
        return `rgb(${adjustedValues.join(',')})`;
    };

}
