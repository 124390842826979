import React from 'react';
import {App, Button, Flex, Form, Modal} from "antd";
import ETInput from "@/pages/auth/components/et-input";
import {MailCodeType, PasswordReg} from "@/constant";
import ETCaptcha from "@/pages/auth/components/et-captcha";
import {UserService} from "@/service/user-service";

interface ForgotPWDProps {
    open: boolean;
    onClose: () => void;
}

const ForgotPWD: React.FC<ForgotPWDProps> = ({open, onClose}) => {
    const form = Form.useForm()[0];
    const {message} = App.useApp();

    const sendMail = async () => {
        const isValid = await form.validateFields(['email']);
        if (!isValid) return;
        await UserService.getMailCode(form.getFieldValue('email'), MailCodeType.FindPassword);
        message.success('已发送, 请查收邮件！');
    }

    const handleSubmit = async (values: any) => {
        const res = await UserService.findPassword(values);
        message.info(res.msg);
        if (res.code === 1000) {
            onClose();
        }
    }

    return (
        <Modal footer={null} style={{maxWidth: 400}} title="找回密码" destroyOnClose open={open} onCancel={onClose}>
            <Form onFinish={handleSubmit} form={form} layout="vertical">
                <Form.Item label="邮箱" name="email" rules={[{
                    required: true,
                    message: '请输入邮箱'
                }, {
                    type: 'email',
                    message: '请输入正确的邮箱'
                }]}>
                    <ETInput type="email" placeholder="邮箱"/>
                </Form.Item>
                <Form.Item label="验证码" name="code" rules={[
                    {
                        required: true,
                        message: '请输入验证码'
                    }
                ]}>
                    <ETCaptcha onCaptcha={sendMail}
                               onChange={({target: {value}}) => {
                                   form.setFieldValue('code', value);
                               }}
                    />
                </Form.Item>
                <Form.Item label="密码" name="password" rules={[{
                    required: true,
                    message: '请输入密码'
                }, {
                    pattern: PasswordReg,
                    message: '请输入正确的密码'
                }]}>
                    <ETInput type="password" placeholder="密码 [6-18位大小写字母、数字]"/>
                </Form.Item>
                <Form.Item>
                    <Flex justify="end">
                        <Button type="primary"
                                htmlType="submit"
                                className={'btn-primary'}>
                            提交</Button></Flex>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ForgotPWD;
