import React, {useEffect} from "react";
import {Outlet, useNavigate} from 'react-router-dom';
import {Flex} from "antd";
import Header from "@/components/header";
import ETSider from "@/components/et-sider";
import {UserService} from "@/service/user-service";
import {useAuthStore} from "@/store/auth";
import {CACHE_KEY, MessageCode} from "@/constant";
import CacheUtils from "@/utils/cache-utils";
import styles from './index.module.less';

const Home: React.FC = () => {
    const {setUserInfo} = useAuthStore();
    const navigate = useNavigate();
    useEffect(() => {
        UserService.checkLogin().then(res => {
            if (res.code === MessageCode.ERROR) {
                CacheUtils.remove(CACHE_KEY.TOKEN);
                navigate('/auth');
            }
            setUserInfo(res.data);
        });
    }, [navigate, setUserInfo]);

    return (
        <Flex style={{
            background: 'rgb(245, 248, 255,.7)',
            backdropFilter: 'blur(20px)'
        }}>
            <ETSider/>
            <Flex style={{
                flexGrow: 1,
                position: 'relative',
            }} vertical>
                <Header/>
                <div className={styles.content}>
                    <Outlet/>
                </div>
            </Flex>
        </Flex>
    )
}

export default Home;
