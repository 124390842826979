import React from 'react';
import styles from './index.module.less';
import {InputProps} from "antd/es/input/Input";
import {ConfigProvider, Input} from "antd";

const ETInput: React.FC<InputProps> = (props) => {
    return (
        <ConfigProvider theme={{
            components: {
                Input: {
                    controlOutline: 'transparent',
                    activeBg: 'transparent',
                    hoverBg: 'transparent',
                    controlOutlineWidth: 0,
                    colorBgContainer: 'transparent'
                }
            }
        }}
        >
            <Input {...props} className={styles['et-input']}/>
        </ConfigProvider>
    )
}

export default ETInput;
