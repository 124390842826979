// 定义类型
import {useEffect, useRef, useState} from "react";

export const useDrag = (elementRef: React.MutableRefObject<HTMLDivElement | null>, sliderRef: React.MutableRefObject<HTMLDivElement | null>,
                        onDrag: (percent: number) => void,
                        onEnd: (percent: number) => void) => {
    const isDragging = useRef(false);
    // 百分比
    const [percent, setPercent] = useState(0);
    const setPercentOnUnDrag = (percent: number) => {
        if (!isDragging.current) {
            setPercent(percent);
        }
    }
    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            isDragging.current = true;
        }
        const handleMouseUp = (e: MouseEvent) => {
            if (isDragging.current) {
                onEnd(percent);
                isDragging.current = false;
            }
        }
        const handleMouseMove = (e: MouseEvent) => {
            if (isDragging.current) {
                const rect = elementRef.current?.getBoundingClientRect();
                if (!rect) return;
                const x = e.clientX - rect.left;
                const width = rect.width;
                setPercent(x / width * 100);
                onDrag(x / width);
            }
        }
        const handleConTrackMouseDown = (e: MouseEvent) => {
            const rect = elementRef.current?.getBoundingClientRect();
            if (!rect) return;
            const x = e.clientX - rect.left;
            const width = rect.width;
            onEnd(x / width * 100);
        }

        sliderRef.current?.addEventListener('mousedown', handleMouseDown);
        document.body.addEventListener('mouseup', handleMouseUp);
        document.body?.addEventListener('mousemove', handleMouseMove);
        elementRef.current?.addEventListener('mousedown', handleConTrackMouseDown);

        return () => {
            sliderRef.current?.removeEventListener('mousedown', handleMouseDown);
            document.body.removeEventListener('mouseup', handleMouseUp);
            document.body.removeEventListener('mousemove', handleMouseMove);
            elementRef.current?.removeEventListener('mousedown', handleConTrackMouseDown);
        }
    }, [elementRef, percent, sliderRef]);
    return {percent, setPercent: setPercentOnUnDrag};
};
