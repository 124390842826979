import { useRef, useState } from 'react';
import { App } from 'antd';

interface UseFileSelectOptions {
    accept?: string; // 控制可接受的文件类型
    multiple?: boolean; // 是否允许选择多个文件
    maxFiles?: number; // 最大选择文件数量
    maxSize?: number; // 单个文件的最大大小（以KB为单位）
}

interface UseFileSelectReturn {
    selectedFiles: File[];
    triggerFileInput: () => void;
    fileInputElement: JSX.Element;
    resetFiles: () => void;
    removeFile: (file: File) => void;
}

const useFileSelect = ({ accept, multiple, maxFiles, maxSize }: UseFileSelectOptions = {}): UseFileSelectReturn => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { message } = App.useApp();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
            const newFiles = Array.from(files);

            if (maxFiles && (selectedFiles.length + newFiles.length) > maxFiles) {
                message.error(`最多只能选择 ${maxFiles} 个文件`);
            } else if (maxSize && newFiles.some(file => file.size > maxSize * 1024)) {
                message.error(`单个文件大小不能超过 ${maxSize} KB`);
            } else {
                setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
            }
        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const resetFiles = () => {
        setSelectedFiles([]);
    };

    const removeFile = (file: File) => {
        setSelectedFiles(prevFiles => prevFiles.filter(f => f !== file));
    };

    const fileInputElement = (
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept={accept}
            multiple={multiple}
        />
    );

    return {
        selectedFiles,
        triggerFileInput,
        fileInputElement,
        resetFiles,
        removeFile,
    };
};

export default useFileSelect;
