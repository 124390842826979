export enum PlayState {
    PLAY = 'play',
    PAUSE = 'pause',
    STOP = 'stop',
}

export const LYR_TIP = <><p>Intro：</p>
    <p>星海深处点点光，而我在追梦，</p>
    <p>清风拂过夜空，带来那熟悉的歌。</p>

    <p>Verse 1：</p>
    <p>素颜映照繁星，微光你闪烁，</p>
    <p>月影轻织暗纹，宛如你笑容，</p>
    <p>悄悄语声传递心中的秘密，</p>
    <p>时光在此停留，难舍这份情。</p>

    <p>Chorus：</p>
    <p>星海深处点点光，而我在追梦，</p>
    <p>清风拂过夜空，带来那熟悉的歌。</p>
    <p>在心底写下你爱的每个字，</p>
    <p>就当我为遇见你留下痕迹。</p>

    <p>Bridge：</p>
    <p>晨曦悄然升起，唤醒了希望，</p>
    <p>如流星划过天际，瞬间闪烁影，</p>
    <p>你的眼神璀璨。</p>

    <p>Chorus：</p>
    <p>星海深处点点光，而我在追梦，</p>
    <p>清风拂过夜空，带来那熟悉的歌。</p>
    <p>在心底写下你爱的每个字，</p>
    <p>就当我为遇见你留下痕迹。</p>

    <p>Outro：</p>
    <p>星海深处点点光，而我在追梦，</p>
    <p>晨曦悄然升起，唤醒了希望。</p></>;
