import React from 'react';
import {renderFrequencyData} from "@/utils/music-utils";
import {AudioStore} from "@/store/music/audio-store";
import styles from './index.module.less';
import {useMusicStore} from "@/store/music";

const Frequency: React.FC = () => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const duration = useMusicStore(s => s.duration);
    React.useEffect(() => {
        if (canvasRef.current && AudioStore.getInstance().audioContext && AudioStore.getInstance().audioAnalyser) {
            renderFrequencyData(AudioStore.getInstance().audioContext!, AudioStore.getInstance().audioAnalyser!, canvasRef.current);
        }
    }, [duration, AudioStore.getInstance().audioContext]);

    return (
        <canvas className={styles.canvas} ref={canvasRef}/>
    )
}

export default Frequency;
