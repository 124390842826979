import Api from "@/api";

export interface Advice {
    username: string;
    description: string;
}

export const RebackService = {
    create(description: string) {
        Api.post('/reback/create', {}, {
            params: {
                description
            }
        })
    },
    list() {
        return Api.get('/reback/list')
    },
    like(rid: number) {
        return Api.post('/reback/like', {}, {
            params: {
                rid
            }
        })
    },
    unlike(rid: number) {
        return Api.post('/reback/unlike', {}, {
            params: {
                rid
            }
        })
    }
}
