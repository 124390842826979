import React, {useEffect} from 'react'
import routes from './router'
import {App as AntDApp, ConfigProvider} from "antd";
import useETTheme from "@/hooks/useETTheme";
import {RouterProvider} from "react-router-dom";
import zh from "antd/locale/zh_CN";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import {detectDeviceType, DeviceType} from "@/utils/device";
import {useNotice} from "@/hooks/useNotice";

const App: React.FC = () => {
    const {tokens, components} = useETTheme();
    const deviceType = detectDeviceType();
    const {showNotice, contextHolder} = useNotice();

    useEffect(() => {
        if (deviceType === DeviceType.Mobile) {
            showNotice("Notice", "检测到你的设备为移动设备，由于短期内会开发独立移动端网页，本次未针对移动设备进行优化，请使用 PC 端访问!!");
        }

    }, [deviceType, showNotice]);
    return (
        <ConfigProvider
            locale={zh}
            theme={{
                token: tokens, components
            }}>
            <ErrorBoundary message={'页面加载失败'} description={'请检查网络连接'}>
                <AntDApp>
                    <RouterProvider router={routes}/>
                    {contextHolder}
                </AntDApp>
            </ErrorBoundary>
        </ConfigProvider>
    )
}

export default App;
