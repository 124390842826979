import React, {useEffect, useRef} from "react";
import styles from './index.module.less';
import {scrollToCurrentTime} from "@/utils/music-utils";
import {useMusicStore} from "@/store/music";

const Lyric: React.FC = () => {
    const {lycInfo, currentTime} = useMusicStore();
    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        scrollRef.current && scrollToCurrentTime(scrollRef.current, currentTime);
    }, [currentTime, scrollRef]);

    return (
        <div
            className={styles['lyric-container']}
        >
            <p className={styles.title}>{lycInfo?.title}ss</p>
            <p className={styles.singer}>{lycInfo?.singer}ss</p>
            <div className={styles['scroll-container']}>
                <div ref={scrollRef} className={styles.scroll}>
                    {
                        lycInfo?.timeLyc.map(item => (
                            <p
                                data-time={item.time}
                                className={styles.item}
                                key={item.time}
                            >
                                {item.lyric}
                            </p>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default Lyric;
