import React, { useRef, useState, useEffect } from 'react';
import styles from './index.module.less';
import { Avatar, Flex, Tag } from 'antd';
import EternalIcon from '@/components/icon';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';

const TopPlayer: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [percent, setPercent] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (!isMouseDown) return;

            const rect = ref.current?.getBoundingClientRect();
            const x = e.clientX - (rect?.left || 0);
            const width = rect?.width || 0;
            const percent = x / width;
            setPercent(percent);
            console.log(percent);
        };

        const handleMouseDown = (e:MouseEvent) => {
            const rect = ref.current?.getBoundingClientRect();
            const x = e.clientX - (rect?.left || 0);
            const width = rect?.width || 0;
            const percent = x / width;
            setPercent(percent);
            console.log(percent);
            setIsMouseDown(true);
        };

        const handleMouseUp = () => {
            setIsMouseDown(false);
        };

        if (ref.current) {
            ref.current.addEventListener('mousedown', handleMouseDown);
            ref.current.addEventListener('mousemove', handleMouseMove);
            ref.current.addEventListener('mouseup', handleMouseUp);
            ref.current.addEventListener('mouseleave', handleMouseUp);

            return () => {
                ref.current?.removeEventListener('mousedown', handleMouseDown);
                ref.current?.removeEventListener('mousemove', handleMouseMove);
                ref.current?.removeEventListener('mouseup', handleMouseUp);
                ref.current?.removeEventListener('mouseleave', handleMouseUp);
            };
        }
    }, [isMouseDown]);

    return (
        <div className={styles['top-player']}>
            <Avatar style={{ flexShrink: 0 }} src="https://gd-hbimg.huaban.com/74880d36470c43f3d359a3d5ac60b5b3464253a5850ce-rtGXyZ" />
            <Flex className={styles['player-control']}>
                <div className={styles['player-btn']}>
                    <StepBackwardOutlined className={styles['btn']} />
                    <EternalIcon className={styles['btn']} type="icon-icon_play" />
                    <StepForwardOutlined className={styles['btn']} />
                    <Tag bordered={false}>20:55/00:88</Tag>
                </div>
                {/*@ts-ignore*/}
                <marquee className={styles['player-title']} behavior="scroll" direction="left">这是滚动文本</marquee>
            </Flex>
            <div ref={ref} className={styles['player-progress']}>
                <div style={{ width: `${percent * 100}%` }} className={styles['progress-bar']}></div>
            </div>
        </div>
    );
};

export default TopPlayer;
