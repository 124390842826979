import {AliasToken, MappingAlgorithm, OverrideToken} from "antd/es/theme/interface";
import {useMemo, useState} from "react";

type ComponentsConfig = {
    [key in keyof OverrideToken]?: OverrideToken[key] & {
    algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[];
};
};
export const token: Partial<AliasToken> = {
    colorPrimary: 'rgb(46,166,241)',
    colorInfo: 'rgb(114,138,192)',
    colorSuccess: '#15e58c',
    colorWarning: '#fac814',
    colorError: '#f55252',
    colorTextBase: 'rgb(45,53,73)',
    colorPrimaryText: 'rgb(73,220,93)',
    borderRadius: 4,
    colorLink: 'rgb(24,79,213)',
};

const borders = {
    borderRadius: 0,
    borderRadiusOuter: 0,
    borderRadiusLG: 0,
    borderRadiusSM: 0,
    borderRadiusXS: 0,
};

export const componentsConfig: ComponentsConfig = {
    Notification: {
        ...borders
    },
    Message: {
        ...borders
    }
}

const useETTheme = () => {
    const [primaryColor, setPrimaryColor] = useState(token.colorPrimary);
    const [bgImg, setBgImg] = useState('');

    const tokens: Partial<AliasToken> = useMemo(() => {
        return {
            ...token,
            colorPrimary: primaryColor,
        }
    }, [primaryColor]);

    const components = useMemo(() => {
        return {
            ...componentsConfig,
        }
    }, []);

    return {
        tokens,
        components,
        bgImg,
        setBgImg,
        setPrimaryColor
    }
}

export default useETTheme;
