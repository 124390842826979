import React, {CSSProperties, forwardRef} from 'react';
import {App, Button} from "antd";

import styles from './index.module.less';
import {copyText} from "@/utils/common-utils";
import {useChatStore} from "@/store/chat";
import {useLocalStorageState} from "ahooks";
import {API} from "@/service/types";
import {CACHE_KEY} from "@/constant";

interface PopupProps {
    mouseUpPosition: { x: number; y: number };
    content: string;
    onFinish: () => void;
}

const PopupBtn: React.ForwardRefRenderFunction<HTMLDivElement, PopupProps> = ({
                                                                                  onFinish,
                                                                                  mouseUpPosition,
                                                                                  content
                                                                              }, ref) => {
    const {x, y} = mouseUpPosition;
    const {message} = App.useApp();
    const addSelected = useChatStore(s => s.addSelected);
    const [server] = useLocalStorageState<API.ServerItem>(CACHE_KEY.CHECK_SERVER);
    const style: CSSProperties = {
        position: 'absolute',
        top: y,
        left: x,
        zIndex: 1000, // 确保弹框在最上层
    };

    const handleCopy = async () => {
        await copyText(content);
        message.success('复制成功');
        onFinish();
    };

    const handleRef = () => {
        if(!server?.ability?.includes("引用")){
            return message.error("该模型不支持引用");
        }
        addSelected(content);
        onFinish();
    }

    return <>
        {
            mouseUpPosition &&
            content
            && <div ref={ref} className={styles.pop} style={style}>
                <Button onClick={handleRef} size="small" type="text">引用</Button>
                <Button onClick={handleCopy} size="small" type="text">复制</Button>
            </div>
        }
    </>;
};

export default forwardRef(PopupBtn);
