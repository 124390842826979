import React from 'react';
import {Button, Empty} from "antd";
import {useNavigate} from "react-router-dom";

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                <Button onClick={() => {
                    navigate('/home/chat')
                }}>
                    返回首页
                </Button>
            </Empty>
        </>
    )
}

export default NotFound;
