import {API} from "@/service/types";

type ContentType = API.ConversationRes | null;

class TempContentSingle {
    get isAutoScroll(): boolean {
        return this.autoScroll;
    }

    set isAutoScroll(value: boolean) {
        this.autoScroll = value;
    }

    private static instance: TempContentSingle;
    private tempContent: ContentType;
    private autoScroll: boolean;
    private subscribers: Array<(state: any) => void>;

    private constructor() {
        this.tempContent = null;
        this.autoScroll = false;
        this.subscribers = [];
    }

    // 获取单例实例
    public static getInstance() {
        if (!TempContentSingle.instance) {
            TempContentSingle.instance = new TempContentSingle();
        }
        return TempContentSingle.instance;
    }

   public getTempContent(): ContentType {
        return this.tempContent;
    }

    public clearTempContent() {
        this.tempContent = null;
        this.notify(this.tempContent);
    }

    // 设置状态并通知订阅者
    public setState(tempContent: ContentType) {
        this.tempContent = tempContent;
        this.notify(tempContent);
    }

    // 订阅状态变化
    public subscribe(callback: (tempContent: ContentType) => void) {
        this.subscribers.push(callback);
    }

    // 通知所有订阅者状态已变化
    private notify(tempContent: ContentType) {
        this.subscribers.forEach(callback => callback(tempContent));
    }
}

// 导出单例实例
export const TempContentSingleInstance = TempContentSingle.getInstance();
