import React, {ClassAttributes, HTMLAttributes, useState} from 'react';
import { Modal, Button } from 'antd';

type LinkComponentProps = ClassAttributes<HTMLElement> & HTMLAttributes<HTMLElement>;

// @ts-ignore
const LinkComponent: React.FC<LinkComponentProps> = ({ children, href }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        window.open(href, '_blank');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const isLink = href.startsWith('http');

    return (
        <>
            {isLink && (
                <a
                    className={'link-component'}
                    onClick={showModal}
                    href={href}
                >
                    {children}
                </a>
            )}
            <Modal title="确认跳转" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>您确定要跳转到该链接吗？</p>
            </Modal>
        </>
    );
};

export default LinkComponent;
