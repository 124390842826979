import React, {useMemo, useState} from 'react';
import styles from './index.module.less';
import {Button, Flex, Image, Spin, Tag, Typography} from "antd";
import {downloadFile, FileInfo, getFileInfo, getFileType, isImageFile} from "@/utils/common-utils";
import EternalIcon from "@/components/icon";
import {CloseCircleOutlined, DownloadOutlined} from '@ant-design/icons';
import {useAsyncEffect} from "ahooks";
import Lottie from "react-lottie";
import uploading from '@/assets/uploading.json';

interface FileItemProps {
    file?: File,
    url?: string,
    fileName?: string,
    fileSize?: number,
    disabled?: boolean,
    onRemove?: (file: File) => void,
    isUploading?: boolean,
}

const Loading = () => {
    return <div>
        <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: uploading,
        }} direction={1} width={36}
        />
    </div>
}

const FileItem: React.FC<FileItemProps> = ({file, isUploading, fileName, fileSize, url, onRemove, disabled}) => {

    const [info, setInfo] = useState<FileInfo>();
    const [src, setSrc] = useState<string>();
    const isImage = useMemo(() => {
        if (file) {
            const isImage = isImageFile(file);
            if (isImage) {
                setSrc(URL.createObjectURL(file));
            }
            return isImage;
        }
        if (url && fileName && fileSize) {
            const isImage = getFileType(fileName) === 'image';
            if (isImage) {
                setSrc(url);
            }
            return isImage;
        }
    }, [file, fileName, fileSize, url]);

    useAsyncEffect(async () => {
        if (file) {
            const newInfo = await getFileInfo(file);
            setInfo(newInfo);
        }
        if (url && fileName && fileSize) {
            setInfo({
                name: fileName,
                size: (Number(fileSize) / 1024 / 1024).toFixed(2),
                type: fileName.split('.').pop() || '',
            });
        }
    }, []);

    const getIcon = (fileName?: string) => {
        if (!fileName) {
            return 'icon-files';
        }
        const fileType = getFileType(fileName);
        switch (fileType) {
            case 'pdf':
                return 'icon-file-pdf';
            case 'word':
                return 'icon-WORD';
            case 'excel':
                return 'icon-excel';
            case 'ppt':
                return 'icon-ppt';
            case "txt":
                return 'icon-txt-full';
            default:
                return 'icon-files';
        }
    }
    return (
        <Spin
            spinning={!!isUploading}
            style={{
                border: '1px solid rgba(142, 150, 171, 0.42)',
                borderRadius: 8,
            }} tip="上传中" indicator={<Loading/>}>
            <Flex className={styles['file-item-wrapper']}>
                {
                    file ?
                        <Button onClick={() => onRemove?.(file)} disabled={disabled}
                                danger
                                type="text"
                                className={styles.close}>
                            <CloseCircleOutlined/>
                        </Button> :
                        <Button
                            size="small"
                            className={styles.download}
                            onClick={() => downloadFile(url!, info?.name || '')}
                        >
                            <DownloadOutlined/>
                        </Button>
                }

                {
                    isImage ?
                        <div className={styles['item-image']}>
                            <Image
                                width={36}
                                height={36}
                                src={src} alt={info?.name}
                                className={styles.image}
                            />
                        </div> :
                        <EternalIcon className={styles.icon} type={getIcon(info?.name)}/>
                }
                <Flex vertical gap={8}>
                    <Typography.Title
                        ellipsis={{rows: 1, suffix: '', expandable: false}}
                        level={5}
                        className={styles['file-name']}
                    >
                        {info?.name}
                    </Typography.Title>
                    <div className={styles['file-info']}>
                        <Tag style={{padding: '0 6px', transform: 'scale(0.9)'}} draggable={true}>
                            {info?.type}
                        </Tag>
                        <span>{info?.size} MB</span>
                    </div>
                </Flex>
            </Flex>
        </Spin>
    )
}

export default FileItem;
