import {LoginFormType, RegFormType} from "@/pages/auth";
import Api from "@/api";
import {MailCodeType} from "@/constant";
import {API} from "@/service/types";

const login = (params: LoginFormType): Promise<API.LoginRes> => {
    return Api.post('/user/login', params)
}

const register = (params: RegFormType): Promise<API.Result<null>> => {
    return Api.post('/user/register', params)
}

const getMailCode = (email: string, type: MailCodeType): Promise<API.Result<null>> => {
    return Api.get('/user/code', {
        params: {
            email,
            type
        }
    })
}

const checkLogin = (): Promise<API.Result<API.LoginResData['userInfo']>> => {
    return Api.get('/user/checkLogin');
}

const logout = (): Promise<API.Result<null>> => {
    return Api.get('/user/logout');
}

const findPassword = (params: Partial<RegFormType>): Promise<API.Result<null>> => {
    return Api.post('/user/findPwd', {}, {
            params
        }
    )
}

export const UserService = {
    login,
    register,
    getMailCode,
    checkLogin,
    logout,
    findPassword
}
