class CacheUtils {
    static set<T>(key: string, value: T): void {
        localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
    }

    static get<T>(key: string, defaultValue?: T): T | undefined {
        const value = localStorage.getItem(key);
        if (value) {
            try {
                return JSON.parse(value);
            } catch (error) {
                return value as T;
            }
        } else {
            return defaultValue;
        }
    }

    static remove(key: string): void {
        localStorage.removeItem(key);
    }
}

export default CacheUtils;
