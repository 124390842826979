import React, {useRef} from "react";
import styles from './index.module.less';
import {useMusicStore} from "@/store/music";

const Album: React.FC = () => {
    const imgRef = useRef<HTMLImageElement>(null);
    const {state, src, color} = useMusicStore(state => ({
        src: state.coverSrc,
        state: state.playState,
        color: state.mailColor
    }));

    const dynamicStyles = {
        album: {
            backgroundImage: `url(${src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }
    };


    return (
        <div className={styles['album-container']}>
            {/*/@ts-ignore*/}
            <div className={styles.album} state={state} style={dynamicStyles.album}/>
            {/*/@ts-ignore*/}
            <div className={styles['disc-wrap']} state={state}>
                {/*@ts-ignore*/}
                <div className={styles.disc} state={state}
                     style={{backgroundColor: color}}>
                    <img ref={imgRef}
                         src={src}
                         className={styles.photo} alt="album">
                    </img>
                </div>
            </div>
        </div>
    )
}
export default Album;
