import Api from "@/api";
import {API} from "@/service/types";

export class MessageService {
    static async getMessage(): Promise<API.Result<API.NoticeItem[]>> {
        return Api.get('/message/getMessages');
    }

    static async readMessage(messageIds: number[]): Promise<API.Result<any>> {
        return Api.post('/message/read', {}, {
            params: {
                messageIds: messageIds.join(",")
            }
        });
    }

    static subscribe(uid: string, callback: (data: any) => void) {
        const eventSource = new EventSource(Api.defaults.baseURL + '/notice/sub/' + uid);

        eventSource.onmessage = (event) => {
            try {
                callback(JSON.parse(event.data));
            } catch (e) {

            }
        };
        eventSource.onerror = (event) => {
            // 重新连接
            eventSource.close();
        };
        eventSource.onopen = (event) => {
            console.log('eventSource open');
        };
    }
}
