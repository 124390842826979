import {create} from "zustand";
import {PlayState} from '@/constant/music';
import {getLycInfo, LycInfo} from "@/utils/music-utils";
import {workerUtils} from "@/utils/woker-utils";
import {WOKER_EVENT_NAMES} from "@/constant";
import {ASS_PROXY_URL} from "@/api";

interface MusicStore {
    coverSrc: string;
    audioSrc: string;
    setAudioSrc: (src: string) => void;
    setCoverSrc: (src: string) => void;
    lycInfo: LycInfo | null;
    setLycInfo: (lyc: string) => void;
    mailColor: string;
    playState: PlayState;
    setPlayState: (playState: PlayState) => void;
    currentTime: number;
    setCurrentTime: (currentTime: number) => void;
    volume: number;
    setVolume: (volume: number) => void;
    duration: number;
    setDuration: (duration: number) => void;
    loop: boolean;
    setLoop: (loop: boolean) => void;
    muted: boolean;
    setMuted: (mute: boolean) => void;
    playbackRate: number;
    setPlaybackRate: (playbackRate: number) => void;
    canplay: boolean;
    setCanplay: (canplay: boolean) => void;
    openPlayer: boolean;
    setOpenPlayer: (openPlayer: boolean) => void;
}

export const useMusicStore = create<MusicStore>((set, get) => {
    return {
        coverSrc: '',
        audioSrc: '',
        mailColor: '#fff',
        playState: PlayState.STOP,
        lycInfo: null,
        currentTime: 0,
        volume: 1,
        duration: 0,
        loop: false,
        muted: false,
        playbackRate: 1,
        canplay: false,
        openPlayer: false,
        setOpenPlayer: (openPlayer: boolean) => {
            set({
                openPlayer
            })
        },
        setCanplay: (canplay: boolean) => {
            set({
                canplay
            })
        },
        setAudioSrc: (src: string) => {
            set({
                audioSrc: src,
            })
        },
        setMuted: (muted: boolean) => {
            set({
                muted
            })
        },
        setPlaybackRate: (playbackRate: number) => {
            set({
                playbackRate
            })
        },
        setLoop: (loop: boolean) => {
            set({
                loop
            })
        },
        setDuration: (duration: number) => {
            set({
                duration
            })
        },
        setVolume: (volume: number) => {
            set({
                volume
            })
        },
        setCurrentTime: (currentTime: number) => {
            set({
                currentTime
            })
        },
        setLycInfo: (lyc: string) => {
            const lycInfo = getLycInfo(lyc);
            set({
                lycInfo
            })
        },
        setPlayState: (playState: PlayState) => {
            set({
                playState
            })
        },
        setCoverSrc: (coverSrc: string) => {
            workerUtils.postMessageToWorker({
                eventName: WOKER_EVENT_NAMES.getMainColor,
                parameters: {
                    src: ASS_PROXY_URL + coverSrc,
                    count: 1,
                }
            }).then(res => {
                set({
                    mailColor: res[0]
                })
            });

            set({
                coverSrc: ASS_PROXY_URL + coverSrc,
            })
        }
    }
});
