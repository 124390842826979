import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import '@/index.less';

import {toggleFullscreen} from "@/utils/common-utils";

document.addEventListener("keydown", function (e) {
    // 检查按键是否为 F11
    if (e.keyCode === 122) {
        e.preventDefault(); // 阻止默认行为
        toggleFullscreen(); // 切换全屏
    }
});

// 2024.8.14 更新版本号
let curVersion = '0.0.1';
let pubVersion = localStorage.getItem('version') || '0';
if (pubVersion !== curVersion) {
    // 清除缓存
    localStorage.setItem('version', curVersion);
}
// 检测版本是否一致

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App/>
);
