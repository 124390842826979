import {message} from "antd";
import {MessageCode} from "@/constant";
import {API} from "@/service/types";
import {useState} from "react";

const responseUtils = async <T extends (...args: any[]) => Promise<API.Result<any>>>(reqFun: T, ...params: Parameters<T>) => {
    const {code, data, msg} = await reqFun(...params);
    if (code !== MessageCode.SUCCESS) {
        message.error(msg);
    }

    return {
        success: (successCb: (data: Awaited<ReturnType<T>>['data']) => void) => {
            if (code === MessageCode.SUCCESS) {
                successCb(data);
            }
        },
        error: (errorCb: (msg: string) => void) => {
            if (code !== MessageCode.SUCCESS) {
                errorCb(msg);
            }
        }
    };
}

export default responseUtils;
