import React, {memo, useEffect, useState} from 'react';
import styles from './index.module.less';
import {Avatar, Flex, message, Tag} from "antd";
import {CACHE_KEY, Role, Status, TextType, USER_REFERENCE_PREFIX} from "@/constant";
import ImageViewer, {IImage} from "@/pages/home/chat/components/markdown-ext/image-viewer";
import {API} from "@/service/types";
import LinkList, {LinkItem} from "@/pages/home/chat/components/markdown-ext/link-list";
import Lottie from "react-lottie";
import loading from "@/assets/loading.json";
import FileItem from "@/pages/home/chat/components/markdown-ext/file-item";

import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkEmoji from 'remark-emoji';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

import CodeComponent from "@/pages/home/chat/components/markdown-ext/code-component";
import classNames from "classnames";
import LinkComponent from '../markdown-ext/link-component';
import useSelectText from "@/hooks/useSelectText";
import PopupBtn from "@/pages/home/chat/components/markdown-ext/popup-btn";

import 'katex/dist/katex.min.css';
import '@/style.less';
import 'highlight.js/styles/a11y-light.css';
import {useAuthStore} from "@/store/auth";
import {useLocalStorageState} from "ahooks";
import {CopyOutlined, EditOutlined} from "@ant-design/icons";
import {copyText} from "@/utils/common-utils";

interface ChatItemProps {
    content: API.ConversationRes;
    onEdit?: (content: string) => void;
}


const parsePlugins = (plugins: API.PluginType[] | undefined): React.ReactNode => {
    if (!plugins) return <></>;

    const pluginGroupByType = plugins.reduce((acc, plugin) => {
        const type = plugin.type;
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(plugin);
        return acc;
    }, {} as Record<string, API.PluginType[]>);

    return (
        <>
            {Object.keys(pluginGroupByType).map(type => {
                const groupedPlugins = pluginGroupByType[type];
                switch (type) {
                    case TextType.WEB:
                        return (
                            <LinkList
                                key={type}
                                links={groupedPlugins.map(item => ({
                                    title: item.title,
                                    url: item.url
                                }) as LinkItem)}
                            />
                        );
                    case TextType.IMAGE:
                        return (
                            <ImageViewer
                                key={type}
                                images={groupedPlugins.map(item => ({
                                    src: item.url,
                                    alt: item.url?.split('/').pop(),
                                }) as IImage)}
                            />
                        );
                    case TextType.FILE:
                        return (
                            <div key={type} className={styles.flist}>
                                {groupedPlugins.map(item => (
                                    <FileItem
                                        key={item.url}
                                        url={item.url}
                                        fileName={item.fileName}
                                        fileSize={item.fileSize}
                                    />
                                ))}
                            </div>
                        );
                    case TextType.REFERENCE_PART:
                        return (
                            <Flex key={type} className={styles.reference}>
                                <Tag bordered={false}>@ AI助手</Tag>
                                {
                                    groupedPlugins.map(item => {
                                        return <React.Fragment key={item.referencePart?.id}>
                                            {item.referencePart?.text.replace(USER_REFERENCE_PREFIX, '')}
                                        </React.Fragment>
                                    })
                                }
                            </Flex>
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
};

const ChatItem: React.FC<ChatItemProps> = ({content, onEdit}) => {
    const {role, status} = content?.part;
    const ref = React.useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState([0, 0]);
    const isAssistant = role !== Role.USER;
    const isLoading = status === Status.QUERYING || status === Status.WAITING || status === Status.DRAWING;
    const [selectedText, setSelectedText] = useState('');
    const popRef = React.useRef<HTMLDivElement>(null);
    const {userInfo} = useAuthStore();
    const {clearSelection} = useSelectText(ref, (selectedText, position) => {
        setPosition([position.x, position.y]);
        setSelectedText(selectedText);
    });

    const clearPop = () => {
        clearSelection();
        setSelectedText('');
    }

    const onMouseDown = (e: MouseEvent) => {
        if (popRef.current && popRef.current.contains(e.target as Node)) {
            return;
        }
        clearPop();
    }

    useEffect(() => {
        window.addEventListener('mousedown', onMouseDown);
        return () => {
            window.removeEventListener('mousedown', onMouseDown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [server] = useLocalStorageState<API.ServerItem>(CACHE_KEY.CHECK_SERVER);

    return (
        <Flex style={{flexDirection: role === 'user' ? 'row-reverse' : 'row'}}>
            {isAssistant ?
                <Avatar
                    className={styles.avatar}
                    src={server?.icon}
                >
                </Avatar> :
                <Avatar
                    src={userInfo?.avatar}
                    className={styles.avatar}>
                    {userInfo?.username?.slice(0, 1)}
                </Avatar>
            }
            <div
                ref={ref}
                className={[styles.item, role === 'user' ? styles.user : styles.system].join(' ')}
            >
                {
                    selectedText && role === Role.ASSISTANT &&
                    <PopupBtn
                        ref={popRef}
                        mouseUpPosition={
                            {
                                x: position[0],
                                y: position[1]
                            }
                        }
                        onFinish={clearPop}
                        content={selectedText}/>
                }
                {
                    content.part.text &&
                    !isLoading &&
                    <>
                        {
                            content.part.role === Role.ASSISTANT ?
                                <ReactMarkdown
                                    className={classNames(styles.markdown, 'md-theme', role + '-code')}
                                    children={content.part.text}
                                    remarkPlugins={
                                        [
                                            remarkGfm,
                                            remarkEmoji,
                                            remarkMath]}
                                    rehypePlugins={
                                        [
                                            rehypeHighlight,
                                            rehypeKatex,
                                            rehypeRaw,
                                        ]
                                    }
                                    components={{
                                        code: CodeComponent,
                                        a: LinkComponent,
                                    }}
                                />
                                :
                                <div >{content.part.text}</div>
                        }
                    </>
                }
                {
                    isLoading ?
                        <div className={styles['loading-wrapper']}>
                            <div className={styles.loading}>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: loading
                                    }}
                                    isClickToPauseDisabled={true}
                                    width={80}
                                    height={80}
                                />
                            </div>
                            <div className={styles['loading-text']}>{content?.part?.text}</div>
                        </div> :
                        <div className={styles.footer}>
                            <CopyOutlined
                                title="复制全部"
                                onClick={
                                    async () => {
                                        await copyText(content.part.text);
                                        message.success('复制成功');
                                    }
                                }
                                className={styles['f-btn']}/>
                            {
                                !isAssistant &&
                                <EditOutlined onClick={
                                    () => {
                                        onEdit?.(content.part.text);
                                    }
                                } title="编辑" className={styles['f-btn']}/>
                            }
                        </div>
                }
                {parsePlugins(content?.plugins)}
            </div>
        </Flex>
    )
}

export default memo(ChatItem);
