import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Flex, message} from "antd";
import styles from './index.module.less';
import TextArea from "antd/es/input/TextArea";
import {RebackService} from "@/service/reback";
import {LikeFilled, LikeOutlined} from "@ant-design/icons";

const Tool: React.FC = () => {
    const [advices, setAdvices] = useState([]);
    const [description, setDescription] = useState("");
    const updateList = () => {
        RebackService.list().then(res => {
            setAdvices(res.data)
        })
    }
    useEffect(() => {
        updateList();
    }, []);

    const handleLike = async (rid: number) => {
        await RebackService.like(rid);
        await updateList();
    }

    const handleUnlike = async (rid: number) => {
        await RebackService.unlike(rid);
        await updateList();
    }

    const submitAdvice = async () => {
        if (!description) {
            return message.error("请输入描述");
        }
        await RebackService.create(description);
        message.success("提交成功");
        setDescription("");
        setTimeout(async () => {
            await updateList();
        }, 100);
    }
    return (
        <div className={styles.tool_wrapper}>
            <Flex gap={16}>
                <Card style={{width: '100%'}} size="small" className={styles.tool} title="已有工具（近期开放）">
                    <ol>
                        <li>
                            <strong>B站视频下载工具</strong>
                            <p>合集+单视频（1080P）</p>
                        </li>

                        <li>
                            <strong>Dou音视频下载工具</strong>
                            <p>合集+主页</p>
                        </li>
                        <li>
                            <strong>谷歌网盘下载工具</strong>
                            <p>网盘大文件下载</p>
                        </li>
                    </ol>
                </Card>
                <Card style={{width: '100%'}} size="small" className={styles.tool} title="规划中[WIP]">
                    <ol>
                        <li>
                            <strong>PPT生成</strong>
                            <p>ai自动生成ppt，教学、比赛、汇报</p>
                        </li>

                        <li>
                            <strong>excel处理</strong>
                            <p>ai自动处理excel，计算，总结</p>
                        </li>
                        <li>
                            <strong>word生成</strong>
                            <p>word内容生成，周报、邮件</p>
                        </li>
                        <li>
                            <strong>图片处理</strong>
                            <p>图片处理，去水印，去背景，去底色</p>
                        </li>
                        <li>
                            <strong>AI 教师</strong>
                            <p>自动出题，自动识别纸质试题批阅（OCR精确度问题）</p>
                        </li>
                    </ol>
                </Card> <Card style={{width: '100%'}} size="small" className={styles.tool} title="征集">
                <Alert
                    style={{marginTop: '16px'}}
                    message="如果你有好的想法，请提交详细描述，针对实用的工具，会考虑开发！"
                    type="info"
                />
                <TextArea value={description} onChange={e => setDescription(e.target.value)}
                          style={{marginTop: '16px'}}
                          rows={2}
                          placeholder="例：一款能够去除图片水印的工具。"/>
                <Button onClick={submitAdvice} type="primary" style={{marginTop: '16px'}}>提交</Button>
            </Card>
            </Flex>
            <Card style={{width: '100%', paddingLeft: 0, marginBottom: 16}} size="small" title="征集列表">
                <div className={styles.list}>
                    {
                        advices.map((item: any) => {
                            return <Flex align="start" style={{marginBottom: 4}} key={item?.id}>
                                <Flex>
                                    {
                                        item?.liked ?
                                            <LikeFilled className={styles.like} onClick={() => handleUnlike(item?.id)}
                                                        style={{color: '#f15858'}}/> :
                                            <LikeOutlined onClick={() => handleLike(item?.id)} className={styles.like}/>
                                    }
                                    {
                                        item?.likeCount
                                    }
                                </Flex>
                                <strong
                                    className={styles.username}
                                    style={{flexShrink: 0, marginRight: 4}}>
                                    {item?.username}
                                </strong>
                                ：
                                <p> {item?.description}</p>
                            </Flex>
                        })
                    }
                </div>
            </Card>

        </div>
    )
}

export default Tool;
