import React, {ChangeEventHandler, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Flex} from "antd";
import ETInput from "@/pages/auth/components/et-input";

interface CaptchaProps {
    total?: number;
    onCountDown?: (count: number) => void;
    onCaptcha?: () => Promise<any>;
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

const ETCaptcha: React.FC<CaptchaProps> = ({
                                               total = 60,
                                               onCountDown = (_) => (_),
                                               onCaptcha = () => void 0,
                                               onChange
                                           }) => {
    const [count, setCount] = useState(0);
    const [counting, setCounting] = useState(false);
    let timer: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef<NodeJS.Timeout>();

    const handleButtonClick = useCallback(async () => {
        try {
            await onCaptcha();
            startCountDown();
        } catch (e) {
            console.error(e);
        }
        // eslint-disable-next-line
    }, [onCaptcha]);

    const startCountDown = useCallback(() => {
        setCount(total);
        onCountDown(count);
        setCounting(true);
        timer.current = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount === 1) {
                    clearInterval(timer.current);
                    setCounting(false);
                }
                return prevCount - 1;
            });
        }, 1000);
    }, [count, onCountDown, total]);
    useCallback(() => {
        clearInterval(timer.current);
        setCount(0);
        setCounting(false);
    }, [timer]);
    useEffect(() => {
        return () => {
            clearInterval(timer.current);
        };
    }, [timer]);

    return (
        <Flex align="center" style={{boxSizing: 'border-box'}}>
            <ETInput
                style={{
                    borderRadius: '4px 0 0 4px'
                }}
                onChange={onChange}
                placeholder="验证码"
            />
            <Button
                type="primary"
                style={{
                    width: 230,
                    padding: 0,
                    overflow: 'hidden',
                    border: 0,
                    borderRadius: '0 4px 4px 0',
                    height: '30px',
                    boxShadow: "none",
                    color: '#FFF',
                    background: count > 0 ? '#717577' : ''
                }}
                disabled={counting} // Disable button when counting
                onClick={handleButtonClick}
            >
                {count > 0 ? `${count}秒后重新获取` : '获取验证码'}
            </Button>
        </Flex>
    );
};

export default React.memo(ETCaptcha);
